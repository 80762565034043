export class GridMap {
  // grid 真实地图
  // map 步长地图

  constructor(step, radius) {
    this.step = step || 10 // 地图网表步距
    this.radius = radius || 10 // 距离障碍半径
    this.box = []
    // 地图网表
    this.map = []
  }

  generate_map() {

    this.x_width = Math.ceil((this.max_x - this.min_x) / this.step)
    this.y_width = Math.ceil((this.max_y - this.min_y) / this.step)

    this.map_x_width = this.x_width
    this.map_y_width = this.y_width

    // 创建空地图
    this.map = new Array()
    for (let i = 0; i < this.x_width + 1; i++) {
      this.map[i] = new Array()
      for (let j = 0; j < this.y_width + 1; j++) this.map[i][j] = 0
    }


    // 设置四周边框
    for (let ix = 0; ix < this.x_width + 1; ix++) {
      this.map[ix][0] = 1
      this.map[ix][this.y_width] = 1
    }

    for (let iy = 0; iy < this.y_width + 1; iy++) {
      this.map[0][iy] = 1
      this.map[this.x_width][iy] = 1
    }

  }

  exportMap() {
    let map = this.map
    let points = []
    for (let i = 0; i < map.length; i++) {
      for (let j = 0; j < map[i].length; j++) {
        if (map[i][j] > 0) {
          points.push([i, j])
        }
      }
    }
    return points
  }

  setBox(box) {
    this.min_x = box[0][0]
    this.min_y = box[0][1]
    this.max_x = box[1][0]
    this.max_y = box[1][1]
  }

  setStep(step) {
    this.step = step
  }
  setRadius(radius) {
    this.radius = radius
  }

  add_obstacle_map(obstacles, radius) {
    //"""按地图坐标添加障碍"""
    let r = radius || this.radius

    obstacles.forEach(p => {
      let coords = this.bounding_box_circle(p, this.radius / this.step)
      coords.forEach(c => {
        if (c[0] < this.x_width && c[1] < this.y_width) this.map[c[0]][c[1]] = 1
      })
    })
  }

  add_obstacle_grid(obstacles, radius) {
    //"""按网格坐标添加障碍"""
    let r = radius || this.radius
    obstacles.forEach(p => {
      let xy = this.calc_map_index_xy(p)
      let coords = this.bounding_box_circle(xy, r / this.step)
      coords.forEach(c => {
        if (c[0] < this.x_width && c[1] < this.y_width) this.map[c[0]][c[1]] = 1
      })
    })
  }

  remove_obstacle_grid(obstacles, radius) {
    //"""地图移除障碍"""
    let r = radius || this.radius
    obstacles.forEach(p => {
      let xy = this.calc_map_index_xy(p)
      let coords = this.bounding_box_circle(xy, r / this.step)
      coords.forEach(c => {
        if (c[0] < this.x_width && c[1] < this.y_width) this.map[c[0]][c[1]] = 0
      })
    })
  }

  remove_obstacle_map(obstacles, radius) {
    //"""按网格移除障碍"""
    let r = radius || this.radius
    obstacles.forEach(p => {
      let coords = this.bounding_box_circle(p, r / this.step)
      coords.forEach(c => {
        if (c[0] < this.x_width && c[1] < this.y_width) this.map[c[0]][c[1]] = 0
      })
    })
  }

  calc_grid_position(index, min_position) {
    let pos = index * this.step + min_position
    return pos
  }

  calc_grid_position_xy(grid_pos) {
    //"""地图(x, y) -> 网格(x, y)"""
    let x = grid_pos[0] * this.step + this.min_x
    let y = grid_pos[1] * this.step + this.min_y
    return [x, y]
  }

  calc_map_index(position, min_pos) {
    return round((position - min_pos) / this.step)
  }

  calc_map_index_xy(pos) {
    //"""网格(x, y) -> 地图(x, y)"""

    let x = Math.round((pos[0] - this.min_x) / this.step)
    let y = Math.round((pos[1] - this.min_y) / this.step)

    return [x, y]
  }

  calc_grid_adsorp_xy(pos) {
    // 将真实点吸附到邻近点位
    let xy = this.calc_map_index_xy(pos)
    xy = this.calc_grid_position_xy(xy)
    return xy
  }

  inside_circle(center, tile, radius) {
    //"""判断在半径内, 采用平方和判断，减少开根"""
    let dx = center[0] - tile[0]
    let dy = center[1] - tile[1]
    let distance_squared = dx * dx + dy * dy
    return distance_squared <= radius * radius
  }

  bounding_box_circle_1(center, radius) {
    // 采用包围盒子来查找半径内的网格, 速度快
    // 参考连接: https://www.redblobgames.com/grids/circle-drawing/
    let top = Math.ceil(center[1] + radius)
    let bottom = Math.floor(center[1] - radius)
    let left = Math.ceil(center[0] - radius)
    let right = Math.floor(center[0] + radius)


    let inside_coords = []
    for (let y = bottom; y < top + 1; y++) {
      for (let x = left; x < right + 1; x++) {
        if (y > 0 && y < this.map_y_width && x > 0 && x < this.map_x_width) {
          if (this.inside_circle(center, [x, y], radius)) inside_coords.push([x, y])
        }
      }
    }
    return inside_coords
  }

  bounding_box_circle(center, radius) {
    // 采用包围盒子来查找半径内的网格, 速度快
    // 参考连接: https://www.redblobgames.com/grids/circle-drawing/
    let top = Math.ceil(center[1] + radius)
    let bottom = Math.floor(center[1] - radius)
    let left = Math.ceil(center[0] - radius)
    let right = Math.floor(center[0] + radius)

    let inside_coords = []
    for (let y = bottom; y < top + 1; y++) {
      for (let x = left; x < right + 1; x++) {
        if (y > 0 && y < this.map_y_width && x > 0 && x < this.map_x_width) {
          inside_coords.push([x, y])
        }
      }
    }

    return inside_coords
  }

  verify(pos) {
    //"""确认pos有效"""
    // let grid_xy = this.calc_grid_position_xy(pos)
    // if (grid_xy[0] < this.min_x) return false
    // if (grid_xy[0] >= this.max_x) return false
    // if (grid_xy[1] < this.min_y) return false
    // if (grid_xy[1] >= this.max_y) return false

    // 障碍检查
    if (this.map[pos[0]][pos[1]] == 1) {

      return false
    }
    return true
  }

  neighbors(pos) {

    //"""获取pos的邻居"""
    let nbs = []
    let x = pos[0]
    let y = pos[1]
    let step = 1
    // 8个方位
    let left = [x - step, y]
    let top = [x, y + step]
    let right = [x + step, y]
    let down = [x, y - step]
    let left_top = [x - step, y + step]
    let left_down = [x - step, y - step]
    let right_top = [x + step, y + step]
    let right_down = [x + step, y - step]
    // nbs = [left, left_top, top, right_top, right, right_down, down, left_down]
    if (this.verify(left)) nbs.push(left)
    if (this.verify(left_top)) nbs.push(left_top)
    if (this.verify(top)) nbs.push(top)
    if (this.verify(right_top)) nbs.push(right_top)
    if (this.verify(right)) nbs.push(right)
    if (this.verify(right_down)) nbs.push(right_down)
    if (this.verify(down)) nbs.push(down)
    if (this.verify(left_down)) nbs.push(left_down)

    return nbs
  }

  get_weight_xy(pos) {
    return this.map[pos[0]][pos[1]]
  }

  cost_Manhattan(a, b, weight) {
    let D = 1
    // 曼哈顿距离
    return D * (Math.abs(a[0] - b[0]) + Math.abs(a[1] - b[1]))
  }

  cost_Diagonal(a, b, weight) {
    //"""方格上的对角线移动代价计算"""
    let D = 1
    let dx = Math.abs(a[0] - b[0])
    let dy = Math.abs(a[1] - b[1])
    let D2 = 1
    // let D2 = 1.41421356
    let di = D * (dx + dy) + (D2 - 2 * D) * Math.min(dx, dy)

    return di
  }

  cost_Euclidean(a, b, weight) {
    //"""方格上的欧几里得距离"""
    let D = 1
    let dx = Math.abs(a[0] - b[0])
    let dy = Math.abs(a[1] - b[1])
    return D * Math.sqrt(dx * dx + dy * dy)
  }
}
