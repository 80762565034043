import * as PIXI from 'pixi.js'
import QedaAxis from './qeda-axis'
import { ShaderPathSrc, ShaderPolygonSrc, ShaderRefInstanceSrc, LayerColor } from './shaders'
import { Library } from './library'

export class Stage {
  constructor(canvas_id) {
    this.canvas = document.getElementById(canvas_id)

    this.width = this.canvas.clientWidth
    this.height = this.canvas.clientHeight

    this.canvas_rulerX = document.getElementById('ruler-x')
    this.canvas_rulerY = document.getElementById('ruler-y')

    this.axis = new QedaAxis(this.width, this.height, 1.0e-6, 1.0e-9)
    this.axis.setRuler(this.canvas_rulerX, this.canvas_rulerY)
    this.library = new Library()

    this.app = new PIXI.Application({
      width: this.width,
      height: this.height,
      backgroundColor: 0x000000,
      resolution: 1,
      antialias: true,
      view: this.canvas,
    })
    this.container_axis = new PIXI.Container()
    this.container_instance = new PIXI.Container()
    this.container_cell = new PIXI.Container()

    this.app.stage.addChild(this.container_axis)
    this.app.stage.addChild(this.container_instance)
    this.app.stage.addChild(this.container_cell)
    this.app.stage.addChild(this.library.container)

    this.mouse_graphics = new PIXI.Graphics()
    this.container_axis.addChild(this.mouse_graphics)

    this.listenerBind()
    this.updateDraw()

    // const fpsCounter = new PixiFps()
    // this.app.stage.addChild(fpsCounter)

    this.textStyle = new PIXI.TextStyle({
      fontFamily: 'Arial',
      fontSize: 12,
      fill: ['#ffffff'], // gradient
    })
    this.info = new PIXI.Text('1212312\r\nnxxx', this.textStyle)
    this.info.x = 0
    this.info.y = 0
    this.app.stage.addChild(this.info)

    // this.testShader()
    // this.testInstance()
    this.frameCount = 0
    this.lastTime = window.performance.now()

    this.app.ticker.add(delta => {

      this.drawMouse()
      this.axis.drawRulerX()
      this.axis.drawRulerY()

      this.library.ticker(delta)
      this.info.text = `
      fps=${this.fps}
      x=${this.axis.mouse_point.x}, y=${this.axis.mouse_point.y}
      scale=${this.axis.scale.toFixed(3)}
      mode=${this.library.select_mode}
      auto_mode=${this.library.auto_mode}
      `
      this.getFps()

      if (this.drag) this.updateDraw()
    })

    this.drag = false
  }

  getFps() {
    this.frameCount += 1
    let curTime = window.performance.now()
    if (curTime - this.lastTime > 1000) {
      // 取固定时间间隔为1秒
      this.fps = this.frameCount
      this.frameCount = 0
      this.lastTime = curTime
    }
  }

  listenerBind() {
    this.mouseWheelListener = this.onMouseWheel.bind(this)
    this.mouseMoveListener = this.onMouseMove.bind(this)
    this.mouseDownListener = this.onMouseDown.bind(this)
    this.mouseUpListener = this.onMouseUp.bind(this)
    this.canvas.addEventListener('mousewheel', this.mouseWheelListener)
    this.canvas.addEventListener('mousemove', this.mouseMoveListener)
    this.canvas.addEventListener('mouseup', this.mouseUpListener)
    this.canvas.addEventListener('mousedown', this.mouseDownListener)
  }

  setCell(name) {
    this.library.loadCell(name)
    // 添加mesh到container
    this.container_cell.removeChildren()

    this.container_cell.addChild(this.library.meshs.cell.paths)
    this.container_cell.addChild(this.library.meshs.cell.polygons)
    this.library.meshs.cell.references.forEach(element => {
      this.container_cell.addChild(element)
    })
    // 居中显示
    this.setCenterTran()
  }

  // 获取最佳视角中心位置
  setCenterTran() {
    let box = this.library.cell.bounding_box()
    const s = 9 / 10
    const window_width = this.width * s
    const window_height = this.height * s
    const box_width = box[1][0] - box[0][0]
    const box_height = box[1][1] - box[0][1]
    let scale = 1
    if (box_height > box_width) {
      scale = window_height / box_height
    } else {
      scale = window_width / box_width
    }
    let dx = this.width / 2 - (box[0][0] + box_width / 2) * scale
    let dy = this.height / 2 + (box[0][1] + box_height / 2) * scale
    this.axis.dx = dx
    this.axis.dy = dy
    this.axis.scale = scale
    this.updateDraw()
  }

  // 鼠标十字
  drawMouse() {
    this.mouse_graphics.clear()
    this.mouse_graphics.lineStyle(1, 0xffff00, 1)
    this.mouse_graphics.moveTo(this.axis.mouse_pixel_x, 0)
    this.mouse_graphics.lineTo(this.axis.mouse_pixel_x, this.height)
    this.mouse_graphics.moveTo(0, this.axis.mouse_pixel_y)
    this.mouse_graphics.lineTo(this.width, this.axis.mouse_pixel_y)

    this.mouse_graphics.moveTo(this.axis.mouse_pixel_x - this.axis.bounds_mouse_range, this.axis.mouse_pixel_y - this.axis.bounds_mouse_range)
    this.mouse_graphics.lineTo(this.axis.mouse_pixel_x - this.axis.bounds_mouse_range, this.axis.mouse_pixel_y + this.axis.bounds_mouse_range)
    this.mouse_graphics.lineTo(this.axis.mouse_pixel_x + this.axis.bounds_mouse_range, this.axis.mouse_pixel_y + this.axis.bounds_mouse_range)
    this.mouse_graphics.lineTo(this.axis.mouse_pixel_x + this.axis.bounds_mouse_range, this.axis.mouse_pixel_y - this.axis.bounds_mouse_range)
    this.mouse_graphics.lineTo(this.axis.mouse_pixel_x - this.axis.bounds_mouse_range, this.axis.mouse_pixel_y - this.axis.bounds_mouse_range)

    this.mouse_graphics.lineStyle(1, 0xffffff, 1)
    this.mouse_graphics.moveTo(this.axis.zero_pixel_x, 0)
    this.mouse_graphics.lineTo(this.axis.zero_pixel_x, this.height)
    this.mouse_graphics.moveTo(0, this.axis.zero_pixel_y)
    this.mouse_graphics.lineTo(this.width, this.axis.zero_pixel_y)
  }

  axisGenShader() {
    this.axisGeometry = new PIXI.Geometry()
    this.axisGeometry.addAttribute(
      'aVertexPosition',
      [
        [0, 0],
        [100, 100],
      ].flat()
    )
    const shader = PIXI.Shader.from(ShaderSelectSrc.vSrc, ShaderSelectSrc.fSrc)
    this.axisMesh = new PIXI.Mesh(this.mouseGeometry, shader)
    this.axisMesh.drawMode = PIXI.DRAW_MODES.LINE_STRIP
    this.container.addChild(this.mouseMesh)
    this.axis_timer = setInterval(() => {}, 100)
  }

  onMouseMove(e) {
    // this.drawMouse()
    this.axis.onMouseMove(e)
    // if (this.library) this.library.mouseMoveQuadTreeSearch(this.axis.bounds_mouse)
    this.library.mouseBox = this.axis.bounds_mouse

  }
  onMouseDown(e) {
    this.axis.setDrag(true, e.button)
    this.axis.onMouseDown(e)
    this.library.onMouseDown(e)
    this.drag = true
  }
  onMouseUp(e) {
    this.axis.setDrag(false, e.button)
    this.axis.onMouseUp(e)
    this.library.onMouseUp(e)
    this.drag = false
  }

  onMouseWheel(e) {
    this.axis.onMouseWheel(e)
    this.library.onMouseWheel(e)
    this.updateDraw()
  }

  updateDraw() {
    // this.container.transform.setFromMatrix(new PIXI.Matrix(this.axis.scale, 0, 0, -this.axis.scale, this.axis.dx, this.axis.dy))
    this.library.container.transform.setFromMatrix(new PIXI.Matrix(this.axis.scale, 0, 0, -this.axis.scale, this.axis.dx, this.axis.dy))
    this.container_instance.transform.setFromMatrix(new PIXI.Matrix(this.axis.scale, 0, 0, -this.axis.scale, this.axis.dx, this.axis.dy))
    this.container_cell.transform.setFromMatrix(new PIXI.Matrix(this.axis.scale, 0, 0, -this.axis.scale, this.axis.dx, this.axis.dy))
  }
}
