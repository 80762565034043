import * as PIXI from 'pixi.js'
import { ShaderPathSrc, ShaderPolygonSrc, ShaderRefInstanceSrc, ShaderSelectSrc, ShaderMouseSearchSrc, LayerColor, ObjColor } from './shaders'

export class GdsMeshFactory {
  constructor(library) {
    this.library = library
  }

  setLibrary(library) {
    this.library = library
  }

  getCell(name) {
    let cell = null
    this.library.cells.forEach(element => {
      if (element.name === name) {
        cell = element
      }
    })
    return cell
  }

  getCellMesh(cell) {
    let paths_mesh = this.getPathsMesh(cell.paths)
    let polygons_mesh = this.getPolygonsMesh(cell.polygons)
    let refs_meshs = this.getRefsMesh(cell.references)
    return {
      paths: paths_mesh,
      polygons: polygons_mesh,
      references: refs_meshs,
    }
  }

  // 路径生成buffer
  genPathBufferBK(path) {
    let vPosition = []
    let vColor = []
    let color = LayerColor[path.layers[0] % 10]
    const points = path.get_points()

    let new_points = [].concat([points[0]], points, [points[points.length - 1]])

    new_points.forEach((p, j) => {
      vPosition.push(p)
      if (j == 0 || j == new_points.length - 1) vColor.push([0, 0, 0, 0])
      else vColor.push(color)
    })
    return {
      vPosition: vPosition.flat(),
      vColor: vColor.flat(),
    }
  }

  // 路径生成buffer
  genPathBuffer(points, color) {
    if (!points) return
    let vPosition = []
    let vColor = []
    let new_points = [].concat([points[0]], points, [points[points.length - 1]])

    new_points.forEach((p, j) => {
      vPosition.push(p)
      if (j == 0 || j == new_points.length - 1) vColor.push([0, 0, 0, 0])
      else vColor.push(color)
    })
    return {
      vPosition: vPosition.flat(),
      vColor: vColor.flat(),
    }
  }

  // 多边形buff生成
  genPolygonBuffer(points, color) {
    let vPosition = []
    let vColor = []
    let new_points = [].concat([points[0]], points, [points[0]], [points[0]])
    new_points.forEach((p, j) => {
      vPosition.push(p)
      if (j == 0 || j == new_points.length - 1) vColor.push([0, 0, 0, 0])
      else vColor.push(color)
    })
    return {
      vPosition: vPosition.flat(),
      vColor: vColor.flat(),
    }
  }

  getPathsBuffer(items) {
    // 创建buffer
    let buffer = {
      vPosition: [],
      vColor: [],
    }
    items.forEach(element => {
      let tbuf = this.genPathBuffer(element.get_points(), LayerColor[element.layers[0] % 10])
      tbuf.vPosition.forEach(element => {
        buffer.vPosition.push(element)
      })
      tbuf.vColor.forEach(element => {
        buffer.vColor.push(element)
      })
      // buffer.vPosition = buffer.vPosition.concat(tbuf.vPosition)
      // buffer.vColor = buffer.vColor.concat(tbuf.vColor)
    })
    return buffer
  }

  getPolygonsBuffer(items) {
    // 创建buffer
    let buffer = {
      vPosition: [],
      vColor: [],
    }
    items.forEach(element => {
      let tbuf = this.genPolygonBuffer(element.get_points(), LayerColor[element.layer % 10])
      tbuf.vPosition.forEach(element => {
        buffer.vPosition.push(element)
      })
      tbuf.vColor.forEach(element => {
        buffer.vColor.push(element)
      })
      // buffer.vPosition = buffer.vPosition.concat(tbuf.vPosition)
      // buffer.vColor = buffer.vColor.concat(tbuf.vColor)
    })
    return buffer
  }

  getPathsMesh(items) {
    if (!items) return
    let buffer = this.getPathsBuffer(items)
    // 生成几何
    const geometry = new PIXI.Geometry()
    geometry.addAttribute('aVertexPosition', buffer.vPosition, 2)
    geometry.addAttribute('aColor', buffer.vColor, 4)
    // 生成shader和mesh
    const shader = PIXI.Shader.from(ShaderPathSrc.vSrc, ShaderPathSrc.fSrc)
    const mesh = new PIXI.Mesh(geometry, shader)
    mesh.drawMode = PIXI.DRAW_MODES.LINE_STRIP

    return mesh
  }

  getPolygonsMesh(items) {
    if (!items) return
    // 创建buffer
    let buffer = this.getPolygonsBuffer(items)
    // 生成几何
    const geometry = new PIXI.Geometry()
    geometry.addAttribute('aVertexPosition', buffer.vPosition, 2)
    geometry.addAttribute('aColor', buffer.vColor, 4)
    // 生成shader和mesh
    const shader = PIXI.Shader.from(ShaderPathSrc.vSrc, ShaderPathSrc.fSrc)
    const mesh = new PIXI.Mesh(geometry, shader)
    mesh.drawMode = PIXI.DRAW_MODES.LINE_STRIP

    return mesh
  }

  // references mesh
  getRefsMesh(items) {
    let refs = {}
    items.forEach((element, i) => {
      const x_reflection = element.x_reflection ? -1 : 1
      // 设置变化矩阵
      const matrix = new PIXI.Matrix()
      matrix.setTransform(element.origin[0], element.origin[1], 0, 0, element.magnification, element.magnification * x_reflection, element.rotation, 0, 0)
      let mat3 = matrix.toArray(true)

      if (refs[element.cell.name]) {
        refs[element.cell.name].count += 1
        refs[element.cell.name].matrixs.push(mat3)
        refs[element.cell.name].ref_id_2_matrix_id[i] = refs[element.cell.name].count - 1
      } else {
        refs[element.cell.name] = {
          count: 1,
          matrixs: [mat3],
          ref_id_2_matrix_id: {},
        }
        refs[element.cell.name].ref_id_2_matrix_id[i] = refs[element.cell.name].count - 1
      }
    })

    let meshs = []
    for (let key in refs) {
      //   if (key !== 'MARKER') continue
      let cell = this.getCell(key)
      let ref = refs[key]
      let paths = cell.get_paths(true, null, null, null)
      let polygons = cell.get_polygons(true, false, null, null, null)

      let mesh = this.cellInstancedShader3(paths, polygons, ref.count, ref.matrixs, 'path')
      meshs.push(mesh)
    }
    return meshs
  }

  cellInstancedShader3(paths, polygons, count, matrixs, type) {
    // if (!paths) return
    // if (!polygons) return

    // let paths_res = this.getMeshPoints(paths, 'path')
    // let polygons_res = this.getMeshPoints(polygons, 'polygon')

    let paths_buffer = this.getPathsBuffer(paths)
    let polygons_buffer = this.getPolygonsBuffer(polygons)

    let vPosition = [].concat(paths_buffer.vPosition, polygons_buffer.vPosition)
    let vColor = [].concat(paths_buffer.vColor, polygons_buffer.vColor)

    // let points_buffer = paths_res.points_buffer.concat(polygons_res.points_buffer)
    // let colors_buffer = paths_res.colors_buffer.concat(polygons_res.colors_buffer)
    // let colors_buffer = res.colors_buffer

    const geometry = new PIXI.Geometry()
    geometry.addAttribute('aVPos', vPosition)
    geometry.instanced = true
    geometry.instanceCount = count

    const m1Size = 3
    const m2Size = 3
    const m3Size = 3

    const stride = 4 * (m1Size + m2Size + m3Size)
    const buffer = new PIXI.Buffer(new Float32Array(geometry.instanceCount * (m1Size + m2Size + m3Size)))
    geometry.addAttribute('aM1', buffer, m1Size, true, PIXI.TYPES.FLOAT, stride, 0, true)
    geometry.addAttribute('aM2', buffer, m2Size, true, PIXI.TYPES.FLOAT, stride, 4 * m1Size * 1, true)
    geometry.addAttribute('aM3', buffer, m3Size, true, PIXI.TYPES.FLOAT, stride, 4 * (m1Size + m2Size), true)
    geometry.addAttribute('aICol', vColor, 4)

    for (let i = 0; i < geometry.instanceCount; i++) {
      const instanceOffset = i * (m1Size + m2Size + m3Size)
      // 设置变化矩阵
      const mat = matrixs[i]
      for (let j = 0; j < mat.length; j++) {
        buffer.data[instanceOffset + j] = mat[j]
      }
    }

    const shader = PIXI.Shader.from(ShaderRefInstanceSrc.vSrc, ShaderRefInstanceSrc.fSrc)
    const mesh = new PIXI.Mesh(geometry, shader)
    mesh.drawMode = PIXI.DRAW_MODES.LINE_STRIP
    return mesh
  }

  // 快速添加buff
  bufferAdd(buffer1, buffer2) {
    let new_buffer = new Float32Array(buffer1.length + buffer2.length)
    for (let i = 0; i < buffer1.length; i++) {
      new_buffer[i] = buffer1[i]
    }
    for (let i = 0; i < buffer2.length; i++) {
      new_buffer[buffer1.length + i] = buffer2[i]
    }
    return new_buffer
  }

  addPaths(cell, mesh, paths) {
    let gds_paths = []
    paths.forEach(element => {
      let path = new QGdstk.FlexPath(element, 1, 0, 'natural', 'flush', 0, null, 1e-2, false, true, 1, 1)
      gds_paths.push(path)
    })
    cell.add(gds_paths)

    let paths_buffer = this.getPathsBuffer(gds_paths)

    let mesh_vPosition = mesh._geometry.getBuffer('aVertexPosition')
    let mesh_vColor = mesh._geometry.getBuffer('aColor')



    let vPosition = this.bufferAdd(mesh_vPosition.data, paths_buffer.vPosition)
    let vColor = this.bufferAdd(mesh_vColor.data, paths_buffer.vColor)

    mesh_vPosition.data = vPosition
    mesh_vColor.data = vColor
    mesh_vPosition.update()
    mesh_vColor.update()
  }

  updatePathsMesh(mesh, paths) {
    let mesh_vPosition = mesh._geometry.getBuffer('aVertexPosition')
    let mesh_vColor = mesh._geometry.getBuffer('aColor')

    let buffer = this.getPathsBuffer(paths)
    mesh_vPosition.data = new Float32Array(buffer.vPosition)
    mesh_vColor.data = new Float32Array(buffer.vColor)
    mesh_vPosition.update()
    mesh_vColor.update()
  }

  // table()

  tableMesh(start, goal) {
    if (!start || !goal) return
    let paths = []
    for (let k in start) {
      if (start[k] && goal[k]) {
        paths.push([start[k].pos, goal[k].pos])
      }
    }
    let buffer = { vPosition: [], vColor: [] }
    paths.forEach(element => {
      let tbuf = this.genPathBuffer(element, ObjColor.table)
      if (tbuf) {
        buffer.vPosition = buffer.vPosition.concat(tbuf.vPosition)
        buffer.vColor = buffer.vColor.concat(tbuf.vColor)
      }
    })

    // 生成几何
    const geometry = new PIXI.Geometry()
    geometry.addAttribute('aVertexPosition', buffer.vPosition, 2)
    geometry.addAttribute('aColor', buffer.vColor, 4)
    // 生成shader和mesh
    const shader = PIXI.Shader.from(ShaderPathSrc.vSrc, ShaderPathSrc.fSrc)
    const mesh = new PIXI.Mesh(geometry, shader)
    mesh.drawMode = PIXI.DRAW_MODES.LINE_STRIP

    return mesh
  }

  getGeoPolygonsMesh(polygons) {
    // 多边形buff生成
    function genPolygonBuffer(points, color) {
      let vPosition = []
      let vColor = []
      let new_points = [].concat([points[0]], points, [points[0]], [points[0]])
      new_points.forEach((p, j) => {
        vPosition.push(p)
        if (j == 0 || j == new_points.length - 1) vColor.push([0, 0, 0, 0])
        else vColor.push(color)
      })
      return {
        vPosition: vPosition.flat(),
        vColor: vColor.flat(),
      }
    }
    function getPolygonsBuffer(items) {
      // 创建buffer
      let buffer = {
        vPosition: [],
        vColor: [],
      }
      items.forEach(element => {
        // let tbuf = genPolygonBuffer(element, LayerColor[Math.floor(Math.random() * 100) % 10])
        let tbuf = genPolygonBuffer(element, LayerColor[5])
        tbuf.vPosition.forEach(element => {
          buffer.vPosition.push(element)
        })
        tbuf.vColor.forEach(element => {
          buffer.vColor.push(element)
        })
      })
      return buffer
    }
    if (!polygons) return
    // 创建buffer
    let buffer = getPolygonsBuffer(polygons)
    // 生成几何
    const geometry = new PIXI.Geometry()
    geometry.addAttribute('aVertexPosition', buffer.vPosition, 2)
    geometry.addAttribute('aColor', buffer.vColor, 4)
    // 生成shader和mesh
    const shader = PIXI.Shader.from(ShaderPathSrc.vSrc, ShaderPathSrc.fSrc)
    const mesh = new PIXI.Mesh(geometry, shader)
    mesh.drawMode = PIXI.DRAW_MODES.LINE_STRIP

    return mesh
  }
}
