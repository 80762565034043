var QGeosJs = require('./geos')
export class LibraryQuadtree {
  constructor() {
    this.cell = null

    this.box = null

    this.paths = null
    this.polygons = null
    this.references = null

    this.mouseMovePathID = -1
    this.mouseMovePolygonID = -1

    this.paths_mouse_search = []
    this.polygons_mouse_search = []
    this.references_mouse_search = []

    this.find_path = null
    this.find_polygon = null

    this.search_time_interval = 5 // ms
    this.search_time_cnt = 0
  }

  getMousePoints() {
    let points = []
    if (this.libraryQuadtree.mouseMovePathID >= 0) {
      points = this.cell.paths[this.libraryQuadtree.mouseMovePathID].get_points()
    }
    if (this.libraryQuadtree.mouseMovePolygonID >= 0) {
      points = this.cell.polygons[this.libraryQuadtree.mouseMovePolygonID].get_points()
      points.push(points[0])
    }
    return points
  }

  mouseSearch(mouseBox) {
    let points = []
    this.search_time_cnt += 1
    if (this.search_time_cnt > this.search_time_interval) {
      this.search_time_cnt = 0
      this.search(mouseBox)

      if (this.mouseMovePathID >= 0) {
        points = this.cell.paths[this.mouseMovePathID].get_points()
      }
      if (this.mouseMovePolygonID >= 0) {
        points = this.cell.polygons[this.mouseMovePolygonID].get_points()
        points.push(points[0])
      }
    }
    return points
  }

  // 获取path的包围盒子
  getPathBox(points) {
    let x_min = Infinity
    let y_min = Infinity
    let x_max = -Infinity
    let y_max = -Infinity
    points.forEach(p => {
      if (p[0] < x_min) x_min = p[0]
      if (p[1] < y_min) y_min = p[1]
      if (p[0] > x_max) x_max = p[0]
      if (p[1] > y_max) y_max = p[1]
    })
    return [
      [x_min, y_min],
      [x_max, y_max],
    ]
  }

  genTree(cell) {
    if (!cell) return
    this.cell = cell
    this.box = cell.bounding_box()
    this.genCellPathsQuadTree()
    this.genCellPolygonsQuadTree()
    // this.paths = this.getCellPathsQuadTree(cell.paths, this.box)
    // this.polygons = this.getCellPolygonsQuadTree(cell.polygons, this.box)
    //   this.references = this.getCellReferencesQuadTree(cell.references, this.box)
  }

  genCellPathsQuadTree() {
    if (!this.cell) return null
    let paths = this.cell.paths
    let box = this.cell.bounding_box()
    if (!paths || !box) return null
    let qcbox = new QGdstk.QuadtreeBox(box[0][0], box[0][1], box[1][0] - box[0][0], box[1][1] - box[0][1])
    let quadtree = new QGdstk.Quadtree(qcbox)
    for (let i = 0; i < paths.length; i++) {
      let b = this.getPathBox(paths[i].get_points())
      let node = new QGdstk.QuadtreeNode(i, b[0][0], b[0][1], b[1][0] - b[0][0], b[1][1] - b[0][1])
      quadtree.add(node)
    }
    this.paths = quadtree
  }

  genCellPolygonsQuadTree() {
    if (!this.cell) return null
    let polygons = this.cell.polygons
    let box = this.cell.bounding_box()
    if (!polygons || !box) return null
    let qcbox = new QGdstk.QuadtreeBox(box[0][0], box[0][1], box[1][0] - box[0][0], box[1][1] - box[0][1])
    let quadtree = new QGdstk.Quadtree(qcbox)
    for (let i = 0; i < polygons.length; i++) {
      let b = polygons[i].bounding_box()
      let node = new QGdstk.QuadtreeNode(i, b[0][0], b[0][1], b[1][0] - b[0][0], b[1][1] - b[0][1])
      quadtree.add(node)
    }
    this.polygons = quadtree
  }

  // 获取cell的quadtree, 提前缓存好
  getCellReferencesQuadTree(references, box) {
    // let box = this.cell.bounding_box()
    let qcbox = new QGdstk.QuadtreeBox(box[0][0], box[0][1], box[1][0] - box[0][0], box[1][1] - box[0][1])
    let quadtree = new QGdstk.Quadtree(qcbox)
    for (let i = 0, len = references.length; i < len; i++) {
      let b = references[i].bounding_box()
      let node = new QGdstk.QuadtreeNode(i, b[0][0], b[0][1], b[1][0] - b[0][0], b[1][1] - b[0][1])
      quadtree.add(node)
    }
    return quadtree
  }

  // 鼠标区域移动四叉树搜索
  search(box) {
    if (!box) return
    this.paths_mouse_search = []
    this.polygons_mouse_search = []
    this.references_mouse_search = []
    // 查询
    let querybox = new QGdstk.QuadtreeBox(box.x_min, box.y_min, box.x_max - box.x_min, box.y_max - box.y_min)
    if (this.paths) this.paths_mouse_search = this.paths.query(querybox)
    if (this.polygons) this.polygons_mouse_search = this.polygons.query(querybox)
    // if (this.references) this.references_mouse_search = this.references.query(querybox)


    let gbox = new QGeosJs('LineString', [
      [box.x_min, box.y_min],
      [box.x_min, box.y_max],
      [box.x_max, box.y_max],
      [box.x_max, box.y_min],
      [box.x_min, box.y_min],
    ])


    this.mouseMovePathID = -1
    this.mouseMovePolygonID = -1
    this.find_path = null
    this.find_polygon = null

    // 几何判断是否有交集
    this.paths_mouse_search.forEach(element => {
      let gpath = new QGeosJs('LineString', this.cell.paths[element.id].get_points())
      let res = gbox.intersection(gpath)
      if (res) {
        this.mouseMovePathID = element.id
        this.find_path = this.cell.paths[this.mouseMovePathID]
        return
      }
    })
    this.polygons_mouse_search.forEach(element => {
      let points = this.cell.polygons[element.id].get_points()
      points.push[points[0]]
      let gpath = new QGeosJs('LineString', points)
      let res = gbox.intersection(gpath)
      if (res) {
        this.mouseMovePolygonID = element.id
        this.find_polygon = this.cell.polygons[this.mouseMovePolygonID]
        return
      }
    })
  }
}
