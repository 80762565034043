const LayerInfo = [
  {
    layerName: 'none',
    lock: false,
    hide: false,
    layerNumber: 0,
    color: '#1955FF',
    shape: 'F1',
    border: 'B1',
    borderColor: '#1955FF',
    isExport: true,
  },
  {
    layerName: 'Metal1',
    lock: false,
    hide: false,
    layerNumber: 1,
    color: '#1955FF',
    shape: 'F1',
    border: 'B1',
    borderColor: '#1955FF',
    isExport: true,
  },
  {
    layerName: 'Metal2',
    lock: false,
    hide: false,
    layerNumber: 2,
    color: '#7DAEF7',
    shape: 'F6',
    border: 'B1',
    borderColor: '#7DAEF7',
    isExport: true,
  },
  {
    layerName: 'Metal3',
    lock: false,
    hide: false,
    layerNumber: 3,
    color: '#9B51EE',
    shape: 'F3',
    border: 'B1',
    borderColor: '#9B51EE',
    isExport: true,
  },
  {
    layerName: 'Metal3-Flip',
    lock: false,
    hide: false,
    layerNumber: 4,
    color: '#C660F1',
    shape: 'F3',
    border: 'B1',
    borderColor: '#C660F1',
    isExport: true,
  },
  {
    layerName: 'Metal4',
    lock: false,
    hide: false,
    layerNumber: 5,
    color: '#A6B65A',
    shape: 'F3',
    border: 'B1',
    borderColor: '#A6B65A',
    isExport: true,
  },
  {
    layerName: 'Metal4-Flip',
    lock: false,
    hide: false,
    layerNumber: 6,
    color: '#7FB65A',
    shape: 'F3',
    border: 'B1',
    borderColor: '#7FB65A',
    isExport: true,
  },
  {
    layerName: 'Indium',
    lock: false,
    hide: false,
    layerNumber: 7,
    color: '#E8CE3B',
    shape: 'F1',
    border: 'B1',
    borderColor: '#E8CE3B',
    isExport: true,
  },
  {
    layerName: 'Indium-Flip',
    lock: false,
    hide: false,
    layerNumber: 8,
    color: '#DEB22B',
    shape: 'F3',
    border: 'B1',
    borderColor: '#DEB22B',
    isExport: true,
  },
  {
    layerName: 'TiN',
    lock: false,
    hide: false,
    layerNumber: 9,
    color: '#B069E7',
    shape: 'F1',
    border: 'B1',
    borderColor: '#B069E7',
    isExport: true,
  },
  {
    layerName: 'TiN-Flip',
    lock: false,
    hide: false,
    layerNumber: 10,
    color: '#D469E7',
    shape: 'F3',
    border: 'B1',
    borderColor: '#D469E7',
    isExport: true,
  },
  {
    layerName: 'Junction',
    lock: false,
    hide: false,
    layerNumber: 11,
    color: '#E60012',
    shape: 'F4',
    border: 'B1',
    borderColor: '#E60012',
    isExport: true,
  },
  {
    layerName: 'Label',
    lock: false,
    hide: false,
    layerNumber: 12,
    color: '#FFFFFF',
    shape: 'F7',
    border: 'B1',
    borderColor: '#00FFFF',
    isExport: true,
  },
]

function hex2rgb(hex) {
  return [((hex & 0xff0000) >> 16) / 255, ((hex & 0x00ff00) >> 8) / 255, (hex & 0x0000ff) / 255, 1]
}

function hexstr2rgb(hex_str) {
  let hex = parseInt(hex_str.substring(1), 16)
  return [((hex & 0xff0000) >> 16) / 255, ((hex & 0x00ff00) >> 8) / 255, (hex & 0x0000ff) / 255, 1]
}

function LayerInfo2RGB(info) {
  let layers = []
  for (let i = 0; i < info.length; i++) {
    layers.push(hexstr2rgb(info[i].color))
  }
  return layers
}

function LayerInfoHexStr(info) {
  let layers = []
  for (let i = 0; i < info.length; i++) {
    layers.push(info[i].color)
  }
  return layers
}

export const LayerColorHex = LayerInfoHexStr(LayerInfo)
export const LayerColor = LayerInfo2RGB(LayerInfo)
// export const LayerColor = [
//   [1, 0, 0, 1],
//   [0, 1, 0, 1],
//   [0, 0, 1, 1],
//   [0.5, 0, 0, 1],
//   [1, 1, 0, 1],
//   [0, 1, 1, 1],
//   [0, 1, 1, 1],
//   [0, 1, 1, 1],
//   [0, 1, 1, 1],
//   [0, 1, 1, 1],
//   [0, 1, 1, 1],
// ]

export const ObjColor = {
  table: [1, 1, 1, 0.8],
}

export const ShaderPathSrc = {
  vSrc: `
    precision mediump float;
    attribute vec2 aVertexPosition;
    attribute vec4 aColor;

    uniform mat3 translationMatrix;
    uniform mat3 projectionMatrix;

    varying vec4 vColor;

    void main() {
        vColor = aColor;
        gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
    }
    `,
  fSrc: `
    precision mediump float;
    varying vec4 vColor;
    void main() {
        gl_FragColor = vColor;
        // gl_FragColor = vec4(vColor, 1.0);
    }
    `,
}

export const ShaderPolygonSrc = {
  vSrc: `
    precision mediump float;
    attribute vec2 aVertexPosition;
    attribute vec4 aColor;

    uniform mat3 translationMatrix;
    uniform mat3 projectionMatrix;

    varying vec4 vColor;

    void main() {
        vColor = aColor;
        gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
    }
    `,
  fSrc: `
    precision mediump float;
    varying vec4 vColor;
    void main() {
        gl_FragColor = vColor;
    }
    `,
}

export const ShaderRefInstanceSrc = {
  vSrc: `
    precision mediump float;
    attribute vec2 aVPos;

    attribute vec3 aM1;
    attribute vec3 aM2;
    attribute vec3 aM3;

    attribute vec4 aICol;

    uniform mat3 translationMatrix;
    uniform mat3 projectionMatrix;

    varying vec4 vCol;

    void main() {
        vCol = aICol;
        mat3 matrix = mat3(vec3(aM1),vec3(aM2),vec3(aM3)); 
        gl_Position = vec4((projectionMatrix * translationMatrix * matrix * vec3(aVPos, 1.0)).xy, 0.0, 1.0);
    }
    `,
  fSrc: `
    precision mediump float;
    varying vec4 vCol;
    void main() {
        gl_FragColor = vCol;
        // gl_FragColor = vec4(vCol, 1.0);
        // gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
    }
    `,
}

export const ShaderSelectSrc = {
  vSrc: `
    precision mediump float;
    attribute vec2 aVertexPosition;

    uniform mat3 translationMatrix;
    uniform mat3 projectionMatrix;

    void main() {
        gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
    }
    `,
  fSrc: `
    precision mediump float;
    void main() {
        gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
    }
    `,
}

export const ShaderMouseSearchSrc = {
  vSrc: `
    precision mediump float;
    attribute vec2 aVertexPosition;

    uniform mat3 translationMatrix;
    uniform mat3 projectionMatrix;

    void main() {
        gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
    }
    `,
  fSrc: `
    precision mediump float;
    void main() {
        gl_FragColor = vec4(1.0, 0.0, 0.0, 1.0);
    }
    `,
}
