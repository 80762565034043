import * as THREE from 'three'
var QGeosJs = require('./geos')

export class GeoLibs {
  constructor() {}

  // 获取path的包围盒子
  getBoundingBox(points) {
    let x_min = Infinity
    let y_min = Infinity
    let x_max = -Infinity
    let y_max = -Infinity
    points.forEach(p => {
      if (p[0] < x_min) x_min = p[0]
      if (p[1] < y_min) y_min = p[1]
      if (p[0] > x_max) x_max = p[0]
      if (p[1] > y_max) y_max = p[1]
    })
    return [
      [x_min, y_min],
      [x_max, y_max],
    ]
  }

  // 切割线
  split_line(line, step) {
    let p1 = line[0]
    let p2 = line[line.length - 1]
    let dx = p2[0] - p1[0]
    let dy = p2[1] - p1[1]
    let length = Math.sqrt(dx * dx + dy * dy)
    if (length <= step) return line
    let num = length / step
    let x_step = dx / num
    let y_step = dy / num

    let res = []
    for (let i = 0, len = parseInt(num) + 1; i < len; i++) {
      res.push([p1[0] + i * x_step, p1[1] + i * y_step])
    }
    res.push(p2)
    return res
  }

  getAngle(p1, p2) {
    let dx = p2[0] - p1[0]
    let dy = p2[1] - p1[1]
    return Math.atan2(dy, dx)
  }

  // 两条线段求交点
  interPoint(line1, line2) {
    let l1 = new QGeosJs('LineString', line1)
    let l2 = new QGeosJs('LineString', line2)
    let inter = l1.intersection(l2)
    if (!inter) return
    if (inter.type == 'Point') {
      return inter.coordinates
    }
  }

  //"""简化曲线，去除平行点位"""
  curve_del_parallel(curve) {
    let new_curve = [curve[0]]
    for (let idx = 0; idx < curve.length - 3; idx++) {
      let a = curve[idx]
      let b = curve[idx + 1]
      let c = curve[idx + 2]
      let ag_a_b = Math.atan2(b[1] - a[1], b[0] - a[0])
      let ag_b_c = Math.atan2(c[1] - b[1], c[0] - b[0])
      let err = Math.abs(ag_a_b - ag_b_c)
      if (err == 0) {
        continue
      } else {
        new_curve.push(b)
      }
    }
    new_curve.push(curve[curve.length - 1])
    return new_curve
  }

  //"""简化曲线，提前线骨骼点, 采用角度法"""
  simple_curve_angle(curve, angle) {
    function run() {
      const len = curve.length
      for (let idx = 0; idx < len - 2; idx++) {
        let a = curve[idx]
        let b = curve[idx + 1]
        let c = curve[idx + 2]
        let ag_a_b = Math.atan2(b[1] - a[1], b[0] - a[0])
        let ag_b_c = Math.atan2(c[1] - b[1], c[0] - b[0])

        let err = Math.abs(ag_a_b - ag_b_c)
        if ((err * 180) / Math.PI <= angle) {
          curve.splice(idx + 1, 1)
          return false
        }
      }
      return true
    }
    while (!run()) {}
    return curve
  }

  pointExtendToLine(pos, direct, length) {
    // """ 按点位和方向生成一定长度的线段 """
    let dx = length * Math.cos(direct)
    let dy = length * Math.sin(direct)
    let line = [pos, [pos[0] + dx, pos[1] + dy]]
    return line
  }

  pointGoToPoint(pos, direct, length) {
    // """ 按点位和方向走到一个点"""
    let dx = length * Math.cos(direct)
    let dy = length * Math.sin(direct)
    return [pos[0] + dx, pos[1] + dy]
  }

  // 多边形判断顺时针或者逆时针
  getPolygonDirection(points) {
    if (!points) return
    let positive = []
    let negative = []
    let len = points.length
    let p1, p2, p3
    for (let i = 0; i < len; i++) {
      if (i == 0) {
        p1 = points[len - 1]
        p2 = points[i]
        p3 = points[i + 1]
      } else if (i == len - 1) {
        p1 = points[i]
        p2 = points[0]
        p3 = points[1]
      } else if (i == len - 2) {
        p1 = points[i]
        p2 = points[i + 1]
        p3 = points[0]
      } else {
        p1 = points[i]
        p2 = points[i + 1]
        p3 = points[i + 2]
      }
      let vec_1_2 = new THREE.Vector2(p2[0] - p1[0], p2[1] - p1[1])
      let vec_2_3 = new THREE.Vector2(p3[0] - p2[0], p3[1] - p2[1])
      let cross = vec_1_2.cross(vec_2_3)
      if (cross > 0) positive.push(cross)
      if (cross < 0) negative.push(cross)
    }

    if (positive.length < negative.length) return 'CW'
    else return 'CCW'
  }

  // 路径移动端点到指定位置
  pathMoveEndPoint(line, p) {
    if (line.length == 2) {
      let p1 = line[0]
      let p2 = line[1]
      let vec_1_p = new THREE.Vector2(p[0] - p1[0], p[1] - p1[1])
      let vec_1_2 = new THREE.Vector2(p2[0] - p1[0], p2[1] - p1[1])
      let cross_1 = vec_1_2.cross(vec_1_p)
      if (cross_1 > 0) {
      }
    }
    if (line.length < 3) return line
    let p1 = line[0]
    let p2 = line[1]
    let p3 = line[2]

    let vec_1_p = new THREE.Vector2(p[0] - p1[0], p[1] - p1[1])
    let vec_1_2 = new THREE.Vector2(p2[0] - p1[0], p2[1] - p1[1])
    let vec_2_3 = new THREE.Vector2(p3[0] - p2[0], p3[1] - p2[1])
    let vec_3_2 = new THREE.Vector2(p2[0] - p3[0], p2[1] - p3[1])

    let cross_1 = vec_1_2.cross(vec_1_p)
    let cross_2 = vec_1_2.cross(vec_2_3)

    let max_len = 1000000000
    let line_p = this.pointExtendToLine(p, vec_1_2.angle(), max_len)
    let line_t = []
    if (cross_1 == 0) {
      line[0] = p
      return line
    }
    if (cross_1 > 0) {
      if (cross_2 > 0) line_t = this.pointExtendToLine(p2, vec_2_3.angle(), max_len)
      if (cross_2 < 0) line_t = this.pointExtendToLine(p2, vec_3_2.angle(), max_len)
    }
    if (cross_1 < 0) {
      if (cross_2 > 0) line_t = this.pointExtendToLine(p2, vec_3_2.angle(), max_len)
      if (cross_2 < 0) line_t = this.pointExtendToLine(p2, vec_2_3.angle(), max_len)
    }
    if (line_p.length == 0 || line_t.length == 0) return
    let inter = this.interPoint(line_p, line_t)
    if (inter) {
      line[0] = p
      line[1] = inter
    }
    return line
  }

  pathMoveEndPoint2(line, p) {
    if (line.length < 3) return
    let p1 = line[0]
    let p2 = line[1]
    let p3 = line[2]

    let ag_1_2 = this.getAngle(p1, p2)
    let ag_2_3 = this.getAngle(p2, p3)
    let ag_3_2 = this.getAngle(p3, p2)

    let max_len = 1000000000
    let line_t1 = this.pointExtendToLine(p2, ag_2_3, max_len)
    let line_t2 = this.pointExtendToLine(p2, ag_3_2, max_len)
    let line_t = [line_t1[1], line_t2[1]]

    let line_p = this.pointExtendToLine(p, ag_1_2, max_len)

    let inter = this.interPoint(line_p, line_t)
    if (inter) {
      line[0] = p
      line[1] = inter
    }
    return line
  }

  offScreenFillCell(cell, scale, distance) {
    if (!cell) return []


    let box = cell.bounding_box()
    if (!box) return []


    let tranX = box[0][0]
    let tranY = box[0][1]


    let paths_2d = new Path2D()
    cell.paths.forEach(element => {
      element.translate(-box[0][0], -box[0][1])
      element.scale(1 / scale, [0, 0])
      let points = element.get_points()

      let p = new Path2D()
      p.moveTo(points[0][0], points[0][1])
      for (let i = 1; i < points.length; i++) {
        p.lineTo(points[i][0], points[i][1])
      }
      paths_2d.addPath(p)
    })

    let polygons_2d = new Path2D()
    cell.polygons.forEach(element => {
      element.translate(-box[0][0], -box[0][1])
      element.scale(1 / scale, 1 / scale, [0, 0])
      let points = element.get_points()

      let p = new Path2D()
      p.moveTo(points[0][0], points[0][1])
      for (let i = 1; i < points.length; i++) {
        p.lineTo(points[i][0], points[i][1])
      }
      p.closePath()
      polygons_2d.addPath(p)
    })

    // 绘制获取图像
    box = cell.bounding_box()
    let width = Math.ceil(box[1][0] - box[0][0])
    let height = Math.ceil(box[1][1] - box[0][1])
    let canvas_off = new OffscreenCanvas(width, height)

    let ctx = canvas_off.getContext('2d')

    ctx.save()

    ctx.lineWidth = 1
    ctx.strokeStyle = 'red'
    ctx.fillStyle = 'red'
    // ctx.translate(-box[0][0], -box[0][1])
    // ctx.scale(1 / scale, 1 / scale)
    ctx.stroke(paths_2d)
    ctx.stroke(polygons_2d)
    // ctx.fill(polygons_2d)
    ctx.restore()

    let img = ctx.getImageData(0, 0, width, height)


    // 获取 填充像素点
    let fill_points = []
    let data = img.data
    let row = 0,
      col = 0
    for (let i = 0; i < data.length; i++) {
      if (i > 0 && i % width == 0) {
        row += 1
      }
      col = i - row * width
      if (data[4 * i] > 0) {
        fill_points.push([col * scale + tranX, row * scale + tranY])
      }
    }

    return fill_points
  }

  // 按方向查找交点, 自动布线，网表生成--获取比特四周点位
  getPolygonAroundInterPoint(points, polygon, step) {
    // function squad(pos, ag) {
    //   let p1 = this.pointGoToPoint(pos, ag + Math.PI / 4, step * 1.4143)
    //   let p2 = this.pointGoToPoint(pos, ag + (Math.PI / 2 + Math.PI / 4), step * 1.4143)
    //   let p3 = this.pointGoToPoint(pos, ag + (Math.PI + Math.PI / 4), step * 1.4143)
    //   let p4 = this.pointGoToPoint(pos, ag + ((3 * Math.PI) / 2 + Math.PI / 4), step * 1.4143)

    //   return [p1, p2, p3, p4, p1]
    // }

    // let points = []
    // paths.forEach(element => {
    //   points.push(element[0])
    //   points.push(element[element.length - 1])
    // })
    let paths_mls = new QGeosJs('MultiPoint', points)

    // 首尾相连
    // if (polygon[0][0] != polygon[polygon.length-1][0] && polygon[0][1] != polygon[polygon.length-1][1])
    let poly_points = polygon
    poly_points.push(poly_points[0])
    // 只沿顺时针
    let direction = this.getPolygonDirection(poly_points)
    if (direction == 'CCW') poly_points = poly_points.reverse()


    let debug_suqad = []

    // let step = 27
    let inter_points = []
    let extend_points = []

    // 计算步长点位
    let step_points = []
    let step_points_repeat = {}
    for (let i = 1; i < poly_points.length; i++) {
      let line = [poly_points[i - 1], poly_points[i]]
      let line_points = this.split_line(line, step * 2)
      line_points.forEach(element => {
        if (!step_points_repeat[element]) {
          step_points.push(element)
          step_points_repeat[element] = true
        }
      })
    }
    // step_points = step_points.filter(function (item, index) {
    //   return step_points.indexOf(item) === index // 因为indexOf 只能查找到第一个
    // })
    let inter_repeat = {}
    let ag = 0
    for (let i = 0; i < step_points.length; i++) {
      let element = step_points[i]
      if (i == step_points.length - 1) {
        ag = this.getAngle(step_points[i - 1], step_points[i])
      } else {
        ag = this.getAngle(step_points[i], step_points[i + 1])
      }
      let ag_same = Math.atan2(step * 2, step)
      let same_len = (step * 2) / Math.sin(ag_same)
      let p1 = this.pointGoToPoint(element, ag + ag_same, same_len)
      let p2 = this.pointGoToPoint(element, ag + (Math.PI - ag_same), same_len)
      let p3 = this.pointGoToPoint(element, ag + (Math.PI + ag_same), same_len)
      let p4 = this.pointGoToPoint(element, ag + (2 * Math.PI - ag_same), same_len)
      let suqad_ply = new QGeosJs('Polygon', [[p1, p2, p3, p4, p1]])
      debug_suqad.push([p1, p2, p3, p4, p1])
      // 计算交点
      let res = suqad_ply.intersection(paths_mls)
      if (res) {
        let pos = null
        if (res.type == 'MultiPoint') {

          let d = Infinity
          res.coordinates.forEach(coord => {
            let distance = Math.pow(element[0] - coord[0], 2) + Math.pow(element[1] - coord[1], 2)

            if (distance < d) {
              pos = coord
              d = distance
            }
          })
        }
        if (res.type == 'Point') {
          pos = res.coordinates
        }
        if (pos) {
          if (!inter_repeat[pos]) {
            let extend_pos = this.pointGoToPoint(pos, ag + Math.PI / 2, 200)
            extend_points.push(extend_pos)
            inter_points.push(pos)
            inter_repeat[pos] = true
          }
        }
      }
    }
    // inter_points = inter_points.filter(function (item, index) {
    //   return inter_points.indexOf(item) === index // 因为indexOf 只能查找到第一个
    // })

    // for (let i = 1; i < poly_points.length; i++) {
    //   let line = [poly_points[i - 1], poly_points[i]]
    //   let ag = this.getAngle(line[0], line[1])

    //   let line_points = this.split_line(line, step * 2)
    //   line_points.forEach((element, i) => {
    //     // let point_p = new QGeosJs('Point', element)
    //     // let res = point_p.buffer(step).intersection(paths_mls)
    //     let p1 = this.pointGoToPoint(element, ag + Math.PI / 4, step * 1.4143)
    //     let p2 = this.pointGoToPoint(element, ag + (Math.PI / 2 + Math.PI / 4), step * 1.4143)
    //     let p3 = this.pointGoToPoint(element, ag + (Math.PI + Math.PI / 4), step * 1.4143)
    //     let p4 = this.pointGoToPoint(element, ag + ((3 * Math.PI) / 2 + Math.PI / 4), step * 1.4143)
    //     let suqad_ply = new QGeosJs('Polygon', [[p1, p2, p3, p4, p1]])
    //     debug_suqad.push([p1, p2, p3, p4, p1])
    //     // 计算交点
    //     let res = suqad_ply.intersection(paths_mls)
    //     if (res) {
    //       let pos = null
    //       if (res.type == 'MultiPoint') {

    //         let d = Infinity
    //         res.coordinates.forEach(coord => {
    //           let distance = Math.pow(element[0] - coord[0], 2) + Math.pow(element[1] - coord[1], 2)

    //           if (distance < d) {
    //             pos = coord
    //             d = distance
    //           }
    //         })
    //       }
    //       if (res.type == 'Point') {
    //         pos = res.coordinates
    //       }
    //       if (pos) {
    //         inter_points.push(pos)
    //       }
    //     }
    //   })
    // }


    // inter_points.forEach((pos, i) => {
    //   this.goal[i] = {
    //     pos: pos,
    //     connect: false,
    //   }
    // })

    // this.textGenerate()
    return {
      inter_points: inter_points,
      extend_points: extend_points,
      debug_suqad: debug_suqad,
    }
  }
}

const test = true

if (test) {


}
