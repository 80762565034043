import * as PIXI from 'pixi.js'
var QGeosJs = require('./geos')
import { ShaderPathSrc, ShaderPolygonSrc, ShaderRefInstanceSrc, ShaderSelectSrc, ShaderMouseSearchSrc, LayerColor } from './shaders'
// import { Cell } from './cell'
import { GridTable } from './grid_table'
import { GdsMeshFactory } from './gdsMeshFactory'
import { LibraryQuadtree } from './libraryQuadtree'

export class Library {
  constructor(library) {
    this.library = library || null
    this.libraryQuadtree = new LibraryQuadtree()

    this.cell = null
    this.cell_layers_and_datatypes = []
    this.cell_quadtree = {
      paths: null,
      paths_mouse_search: [],
      polygons: null,
      polygons_mouse_search: [],
      references: null,
      references_mouse_search: [],
    }

    // mesh显示
    this.meshs = {
      cell: {
        paths: null,
        polygons: null,
        references: null,
      },
    }

    this.container = new PIXI.Container()

    this.container_cell = new PIXI.Container()
    this.container.addChild(this.container_cell)

    this.mouseMesh = null
    this.mouseGeometry = null
    this.mouseBox = []

    this.select_mode = 0 // 1: line, 2: polygon
    this.select_one = null
    this.select_multi = []

    this.auto_mode = 0 // 1: start, 2: pad

    this.gridTable = new GridTable()
    this.container.addChild(this.gridTable.container)

    this.gdsMeshFactory = new GdsMeshFactory()
    this.addListener()
  }

  addListener() {
    window.bus.$on('autoline_select_mode', data => {
      if (this.select_mode == 1) this.select_mode = 0
      else this.select_mode = 1
      this.auto_mode = 0
    })
    window.bus.$on('autoline_select_polygon_start', data => {
      this.select_mode = 1
      this.auto_mode = 1
    })
    window.bus.$on('autoline_select_polygon_goal', data => {
      this.select_mode = 1
      this.auto_mode = 2
    })

    window.bus.$on('autoline_remove_paths', data => {
      // this.cellAddPaths(data)
      this.cell.remove(this.gridTable.gds_paths)
      this.gdsMeshFactory.updatePathsMesh(this.cell_mesh.paths, this.cell.paths)
    })

    window.bus.$on('autoline_get_pad', data => {
      this.gridTable.getPad(data)
    })
    window.bus.$on('autoline_bit_offset', data => {
      this.gridTable.setBitPointOffset(data)
    })

    window.bus.$on('autoline_auto_connect', data => {

      this.gridTable.autoConnectRun(data)
      this.cell.add(this.gridTable.gds_paths)
      this.gdsMeshFactory.updatePathsMesh(this.cell_mesh.paths, this.cell.paths)
      this.libraryQuadtree.genCellPathsQuadTree()
    })

    window.bus.$on('autoline_auto_connect_clear', data => {

      if (!this.gridTable.gds_paths) return
      this.cell.remove(this.gridTable.gds_paths)
      this.gdsMeshFactory.updatePathsMesh(this.cell_mesh.paths, this.cell.paths)
      // this.autoConnectRun(data)
    })
  }

  removeListener() {
    window.bus.$off('save')
    window.bus.$off('func')
  }

  setLibrary(library) {
    this.library = library
    this.gdsMeshFactory.setLibrary(library)
  }

  getCell(name) {
    let cell = null
    this.library.cells.forEach(element => {
      if (element.name === name) {
        cell = element
      }
    })
    return cell
  }

  loadCell(name) {
    this.container.removeChildren()

    this.cell = this.getCell(name)


    if (!this.cell) {

      return
    }

    this.libraryQuadtree.genTree(this.cell)

    // cell mesh 生成
    this.cell_mesh = this.gdsMeshFactory.getCellMesh(this.cell)
    this.meshs.cell = this.cell_mesh
    this.mouseGenShader()

    this.getCellLayers()
    this.gridTable = new GridTable()
    this.gridTable.setCell(this.cell)

    this.container.addChild(this.gridTable.container)
  }

  // cellAddPaths(paths) {
  //   let gds_paths = []
  //   paths.forEach(element => {
  //     if (element.length > 0) {
  //       let path = new QGdstk.FlexPath(element, 0, 0, 'natural', 'flush', 0, null, 1e-2, true, true, 1, 0)
  //       gds_paths.push(path)
  //     }
  //   })
  //   if (gds_paths.length > 0) {
  //     this.cell.add(gds_paths)
  //     this.gdsMeshFactory.updatePathsMesh(this.cell_mesh.paths, this.cell.paths)
  //   }
  //   // this.cell_quadtree
  // }
  cellAddPaths(paths) {
    if (paths.length > 0) {
      this.cell.add(gds_paths)
      this.gdsMeshFactory.updatePathsMesh(this.cell_mesh.paths, this.cell.paths)
    }
  }

  getCellLayers() {

    let lib = new QGdstk.Library('layer', 10e-6, 10e-9)
    lib.add(this.cell)
    lib.add(this.cell.dependencies(true))
    this.cell_layers_and_datatypes = lib.layers_and_datatypes()

  }

  mouseGenShader() {
    this.mouseGeometry = new PIXI.Geometry()
    this.mouseGeometry.addAttribute('aVertexPosition', [])
    const shader = PIXI.Shader.from(ShaderMouseSearchSrc.vSrc, ShaderMouseSearchSrc.fSrc)
    this.mouseMesh = new PIXI.Mesh(this.mouseGeometry, shader)
    this.mouseMesh.drawMode = PIXI.DRAW_MODES.LINE_STRIP
    this.container.addChild(this.mouseMesh)
  }

  mouseSelectObjShader() {
    this.selectGeometry = new PIXI.Geometry()
    this.selectGeometry.addAttribute('aVertexPosition', [])
    const shader = PIXI.Shader.from(ShaderSelectSrc.vSrc, ShaderSelectSrc.fSrc)
    this.selectMesh = new PIXI.Mesh(this.selectGeometry, shader)
    this.selectMesh.drawMode = PIXI.DRAW_MODES.LINE_STRIP
    this.container_cell.addChild(this.selectMesh)
  }

  // 鼠标区域移动四叉树搜索

  mouseSearchShow(points) {
    if (!this.mouseGeometry) return
    let buffer = this.mouseGeometry.getBuffer('aVertexPosition')
    let data = new Float32Array(points.flat())
    buffer.data = data
    buffer.update()
  }

  onMouseMove(e) {}
  onMouseDown(e) {
    this.select_one = { type: '', id: -1 }
    if (this.select_mode == 1) {
      this.select_mode = 0
    }

    if (this.auto_mode == 1) {
      this.gridTable.getBitPoint(this.cell.paths, this.libraryQuadtree.find_polygon)
      this.auto_mode = 0
      this.mouseSearchShow([])
    }

    if (this.auto_mode == 2) {
      this.gridTable.genWBPad(this.libraryQuadtree.find_polygon.bounding_box(), 573, 973, 1422, 1830, 815)
      this.auto_mode = 0
    }
  }
  onMouseUp(e) {

  }

  onMouseWheel(e) {}

  ticker(delta) {
    if (this.select_mode == 1 || this.select_mode == 2) {
      let res = this.libraryQuadtree.mouseSearch(this.mouseBox)
      if (res.length > 0) this.mouseSearchShow(res)
    }
    this.gridTable.updateScale(this.container.transform)
    // this.gridTable.ticker(delta)
  }
}
