import * as PIXI from 'pixi.js'
var QGeosJs = require('./geos')
import { AStar } from './astar'
import { GdsMeshFactory } from './gdsMeshFactory'
import { GeoLibs } from './GeoLibs'
import { parseTime } from '@/utils/utils'
// var AutoToolConnectLine = require('./connect_line')

// const work = new Worker('works/work.js')
// work.postMessage('hello worker')
// work.onmessage = e => {

//   // 主进程收到了子进程发出的信息：你好，我是子进程！
//   // work.terminate()
// }
const geolibs = new GeoLibs()

export class GridTable {
  constructor() {
    this.cell = null

    this.astarWorker = new Worker('works/work.js')

    this.wbpad = {}
    this.start = {}
    this.goal = {}

    this.gds_paths = []

    this.texts = []

    // this.astar = new AStar()
    this.astar = null

    this.meshFactory = new GdsMeshFactory()

    // 显示
    this.textStyle = new PIXI.TextStyle({
      fontFamily: 'Arial',
      fontSize: 12,
      fill: ['#ffffff'], // gradient
    })

    this.container = new PIXI.Container()
    // this.text = new PIXI.Text('GridTable', this.textStyle)
    // this.text.x = 1000
    // this.text.y = 1000
    // this.container.addChild(this.text)

    this.text_graphics = new PIXI.Graphics()
    this.text_graphics.lineStyle(2, 0xfeeb77, 1)
    // this.text_graphics.beginFill(0xde3249)
    this.text_graphics.drawRect(100, 100, 100, 100)
    // this.text_graphics.endFill()

    this.container.addChild(this.text_graphics)

    this.text_container = new PIXI.Container()
    this.container.addChild(this.text_container)

    this.mesh_table = null
    this.addListener()
  }

  setCell(cell) {
    this.cell = cell
    this.setGraphBox(this.cell.bounding_box())
  }

  setGraphBox(box) {
    this.astarWorker.postMessage({
      code: 'setBox',
      data: box,
    })
  }

  addListener() {
    // window.bus.$on('autoline_auto_connect', data => {

    //   this.autoConnectRun(data)
    // })
    // window.bus.$on('autoline_auto_connect_clear', data => {

    //   // this.autoConnectRun(data)
    // })
    window.bus.$on('autoline_table_gen', data => {

      this.mesh_table = this.meshFactory.tableMesh(this.start, this.goal)
      if (this.mesh_table) this.container.addChild(this.mesh_table)
    })
  }

  textGenerate() {
    this.texts = []
    for (let k in this.start) {
      const text = new PIXI.Text(`${k}`, this.textStyle)
      text.x = this.start[k].end_pos[0]
      text.y = this.start[k].end_pos[1]
      this.texts.push(text)
    }
    for (let k in this.goal) {
      const text = new PIXI.Text(`${k}`, this.textStyle)
      text.x = this.goal[k].end_pos[0]
      text.y = this.goal[k].end_pos[1]
      this.texts.push(text)
    }
    this.text_container.removeChildren()
    this.texts.forEach(element => {
      this.text_container.addChild(element)
    })
  }

  textPointUpdate(scale) {
    this.text_graphics.clear()
    // this.text_graphics.lineStyle(1, 0xfeeb77, 1)
    this.text_graphics.beginFill(0xde3249)
    const s = 3 / scale
    for (let k in this.start) {
      this.text_graphics.drawRect(this.start[k].end_pos[0] - s, this.start[k].end_pos[1] - s, s * 2, s * 2)
    }
    this.text_graphics.endFill()
    this.text_graphics.beginFill(0x00ff00)
    for (let k in this.goal) {
      this.text_graphics.drawRect(this.goal[k].end_pos[0] - s, this.goal[k].end_pos[1] - s, s * 2, s * 2)
    }
    this.text_graphics.endFill()
  }

  updateScale(transform) {
    this.textPointUpdate(transform.scale.x)
    this.texts.forEach(element => {
      element.scale.x = 1 / transform.scale.x
      element.scale.y = -1 / transform.scale.y
    })
  }

  // 获取比特四周点位
  getBitPoint(paths, polygon) {
    if (this.select_mode == 0) return

    let paths_end_points = []
    paths.forEach(element => {
      let points = element.get_points()
      paths_end_points.push(points[0])
      paths_end_points.push(points[points.length - 1])
    })
    // let inter_points = geolibs.getPolygonAroundInterPoint(paths_end_points, polygon.get_points(), 30)
    let res = geolibs.getPolygonAroundInterPoint(paths_end_points, polygon.get_points(), 10)

    res.inter_points.forEach((pos, i) => {
      this.goal[i] = {
        pos: res.extend_points[i],
        end_pos: [pos[0], pos[1]],
        connect: false,
      }
    })


    // // 调试显示
    // let bit_squard_mesh = this.container.getChildByName('debug')
    // if (bit_squard_mesh) {
    //   this.container.removeChild(bit_squard_mesh)
    // }
    // let mesh = this.meshFactory.getGeoPolygonsMesh(res.debug_suqad)
    // mesh.name = 'debug'
    // this.container.addChild(mesh)

    this.textGenerate()
  }

  setBitPointOffset(offset) {


    // let offset_goal = {}
    // let max_i = Object.keys(this.goal).length
    // for (let k in this.goal) {
    //   // points.push(this.goal[k].pos)
    //   let k_n = parseInt(k)

    //   if (k_n - offset >= 0) {
    //     offset_goal[k] = JSON.parse(JSON.stringify(this.goal[k_n + offset]))
    //   } else {
    //     offset_goal[k] = JSON.parse(JSON.stringify(this.goal[max_i - offset - 1 + k_n]))
    //     // offset_goal[k] = this.goal[]
    //   }
    // }

    // this.goal = offset_goal


    let points = []
    let end_pos = []
    for (let k in this.goal) {
      points.push(this.goal[k].pos)
      end_pos.push(this.goal[k].end_pos)
    }
    var right = points.slice(offset, points.length)
    var left = points.slice(0, offset)

    var end_right = end_pos.slice(offset, end_pos.length)
    var end_left = end_pos.slice(0, offset)

    let offset_points = right.concat(left)
    let end_offset_points = end_right.concat(end_left)
    offset_points.forEach((pos, i) => {
      this.goal[i] = {
        pos: pos,
        end_pos: end_offset_points[i],
        connect: false,
      }
    })

    this.textGenerate()
  }

  genWBPad(box, d1 = 573, d2 = 973, l1 = 1422, l2 = 1830, step = 815) {
    // """
    // ------------------------------------------
    // |     |(d1)  |
    // |     V      |(d2)
    // |     o      v
    // |      ------o--------------------
    // |      ——————>
    // |      (step)
    // |————>
    // | (d3)
    // """
    function edge(line, type) {
      let p1 = line[0]
      let p2 = line[1]
      let dx = p2[0] - p1[0]
      let dy = p2[1] - p1[1]
      let length = Math.sqrt(dx * dx + dy * dy)
      let ag = Math.atan2(dy, dx)

      let center_x = p1[0] + (length / 2) * Math.cos(ag)
      let center_y = p1[1] + (length / 2) * Math.sin(ag)

      let pos = []
      let left = []
      let right = []

      let step_x = step * Math.cos(ag)
      let step_y = step * Math.sin(ag)

      if (type == 'two') {
        for (let i = 0; i < 1000; i++) {
          let distance = i * step + step / 2
          if (distance >= length / 2) break
          if (i == 0) {
            left.push([center_x - step_x / 2, center_y - step_y / 2])
            right.push([center_x + step_x / 2, center_y + step_y / 2])
          } else {
            left.push([center_x - step_x / 2 - i * step_x, center_y - step_y / 2 - i * step_y])
            right.push([center_x + step_x / 2 + i * step_x, center_y + step_y / 2 + i * step_y])
          }
        }
        pos = pos.concat(left.reverse())
        pos = pos.concat(right)
      }
      if (type == 'center') {
        for (let i = 1; i < 1000; i++) {
          let distance = (i - 1) * step
          if (distance >= length / 2) break
          left.push([center_x - i * step_x, center_y - i * step_y])
          right.push([center_x + i * step_x, center_y + i * step_y])
        }
        pos = pos.concat(left.reverse())
        pos.push([center_x, center_y])
        pos = pos.concat(right)
      }
      return pos
    }

    function comPos(pos1, pos2) {
      let pos = []
      for (let i = 0; i < pos1.length; i++) {
        pos.push(pos1[i])
        if (i < pos2.length) pos.push(pos2[i])
      }
      return pos
    }

    let min_x = box[0][0]
    let min_y = box[0][1]
    let max_x = box[1][0]
    let max_y = box[1][1]

    let pos_top_1 = edge(
      [
        [min_x + l1, max_y - d1],
        [max_x - l1, max_y - d1],
      ],
      'two'
    )
    let pos_top_2 = edge(
      [
        [min_x + l2, max_y - d2],
        [max_x - l2, max_y - d2],
      ],
      'center'
    )
    let pos_top = comPos(pos_top_1, pos_top_2)

    let pos_right_1 = edge(
      [
        [max_x - d1, max_y - l1],
        [max_x - d1, min_y + l1],
      ],
      'two'
    )
    let pos_right_2 = edge(
      [
        [max_x - d2, max_y - l2],
        [max_x - d2, min_y + l2],
      ],
      'center'
    )
    let pos_right = comPos(pos_right_1, pos_right_2)

    let pos_bottom_1 = edge(
      [
        [max_x - l1, min_y + d1],
        [min_x + l1, min_y + d1],
      ],
      'two'
    )
    let pos_bottom_2 = edge(
      [
        [max_x - l2, min_y + d2],
        [min_x + l2, min_y + d2],
      ],
      'center'
    )
    let pos_bottom = comPos(pos_bottom_1, pos_bottom_2)

    let pos_left_1 = edge(
      [
        [min_x + d1, min_y + l1],
        [min_x + d1, max_y - l1],
      ],
      'two'
    )
    let pos_left_2 = edge(
      [
        [min_x + d2, min_y + l2],
        [min_x + d2, max_y - l2],
      ],
      'center'
    )
    let pos_left = comPos(pos_left_1, pos_left_2)

    let wbpad_points = [].concat(pos_top, pos_right, pos_bottom, pos_left)
    wbpad_points.forEach((pos, i) => {
      this.wbpad[i] = {
        pos: [pos[0], pos[1]],
        connect: false,
      }
    })


    // pos_top + pos_right + pos_bottom + pos_left
    for (let i = 0; i < pos_top.length; i++) pos_top[i][1] = max_y - 1.2 * d2
    for (let i = 0; i < pos_right.length; i++) pos_right[i][0] = max_x - 1.2 * d2
    for (let i = 0; i < pos_bottom.length; i++) pos_bottom[i][1] = min_y + 1.2 * d2
    for (let i = 0; i < pos_left.length; i++) pos_left[i][0] = min_x + 1.2 * d2

    let points = [].concat(pos_top, pos_right, pos_bottom, pos_left)
    points.forEach((pos, i) => {
      this.start[i] = {
        pos: pos,
        connect: false,
      }
    })
    this.textGenerate()
  }

  // 自动识别四周pad, 采用box盒子，交叉分成四个三角形区域
  getPad(name) {


    let pad = []
    this.cell.references.forEach(ref => {
      if (ref.cell.name == name) pad.push(ref.origin)
    })

    let box = geolibs.getBoundingBox(pad)

    let x_min = box[0][0]
    let y_min = box[0][1]
    let x_max = box[1][0]
    let y_max = box[1][1]
    let center = [x_min + (x_max - x_min) / 2, y_min + (y_max - y_min) / 2]

    let top_triangle = [[x_min, y_max], center, [x_max, y_max], [x_min, y_max]]
    let left_triangle = [[x_min, y_min], center, [x_min, y_max], [x_min, y_min]]
    let down_triangle = [[x_min, y_min], center, [x_max, y_min], [x_min, y_min]]
    let right_triangle = [[x_max, y_min], center, [x_max, y_max], [x_max, y_min]]


    let top_geo = new QGeosJs('Polygon', [top_triangle])
    let left_geo = new QGeosJs('Polygon', [left_triangle])
    let down_geo = new QGeosJs('Polygon', [down_triangle])
    let right_geo = new QGeosJs('Polygon', [right_triangle])

    let pad_geo = new QGeosJs('MultiPoint', pad)

    let top_inter = pad_geo.intersection(top_geo)
    let left_inter = pad_geo.intersection(left_geo)
    let down_inter = pad_geo.intersection(down_geo)
    let right_inter = pad_geo.intersection(right_geo)

    let top = []
    if (top_inter.type == 'MultiPoint') {
      top = top_inter.coordinates.sort((a, b) => {
        return a[0] - b[0]
      })
    }
    let left = []
    if (left_inter.type == 'MultiPoint') {
      left = left_inter.coordinates.sort((a, b) => {
        return a[1] - b[1]
      })
    }
    let right = []
    if (right_inter.type == 'MultiPoint') {
      right = right_inter.coordinates.sort((a, b) => {
        return b[1] - a[1]
      })
    }

    let down = []
    if (down_inter.type == 'MultiPoint') {
      down = down_inter.coordinates.sort((a, b) => {
        return b[0] - a[0]
      })
    }

    // wbpad 真实点位
    let wbpad_points = [].concat(top, right, down, left)
    // wbpad_points.forEach((pos, i) => {
    //   this.wbpad[i] = {
    //     pos: [pos[0], pos[1]],
    //     connect: false,
    //   }
    // })

    wbpad_points.forEach((pos, i) => {
      this.start[i] = {
        pos: [],
        end_pos: [pos[0], pos[1]],
        connect: false,
      }
    })

    let top_box = geolibs.getBoundingBox(top)
    let down_box = geolibs.getBoundingBox(down)
    let left_box = geolibs.getBoundingBox(left)
    let right_box = geolibs.getBoundingBox(right)

    let tran = 50

    for (let i = 0; i < top.length; i++) top[i][1] = top_box[0][1] - tran
    for (let i = 0; i < down.length; i++) down[i][1] = down_box[1][1] + tran
    for (let i = 0; i < right.length; i++) right[i][0] = right_box[0][0] - tran
    for (let i = 0; i < left.length; i++) left[i][0] = left_box[1][0] + tran

    let points = [].concat(top, right, down, left)
    points.forEach((pos, i) => {
      this.start[i].pos = [pos[0], pos[1]]
      // this.start[i] = {
      //   pos: [pos[0], pos[1]],
      //   connect: false,
      // }
    })
    this.textGenerate()
    // this.getAvoidLayer()
  }

  getAvoidLayer(conf) {
    if (!conf) return []
    let layers_points = {}
    for (let k in conf) {
      let paths = this.cell.get_paths(true, null, conf[k].layer, conf[k].datatype)
      let polygons = this.cell.get_polygons(true, false, null, conf[k].layer, conf[k].datatype)
      if (paths.length == 0 && polygons.length == 0) continue
      let cell = new QGdstk.Cell('layer')
      cell.add(paths)
      cell.add(polygons)
      layers_points[conf[k].layer] = geolibs.offScreenFillCell(cell, 10, conf[k].distance)
    }

    return layers_points
  }

  autoConnectRunWorker(conf) {

    let avoidLayerPoints = this.getAvoidLayer(conf.avoid)

    this.astarWorker.onmessage = e => {

      if (e.data.code == 'searchArrReturn') {
        let data = e.data.data

        let paths = []
        for (let k in data) {
          let path = data[k]
          if (k == 155) {

          }
          if (path.length > 0) {
            path = geolibs.pathMoveEndPoint(data[k], this.start[k].pos)

            path = geolibs.pathMoveEndPoint(path.reverse(), this.goal[k].pos)

            path = [].concat([this.wbpad[k].pos], path.reverse())

          } else {

          }
          if (path.length == 2) {

          }
          paths.push(path)
        }
        window.bus.$emit('autoline_add_paths', paths)
      }
      // 主进程收到了子进程发出的信息：你好，我是子进程！
      // work.terminate()
    }

    let start_arr = []
    let goal_arr = []
    this.astarWorker.postMessage({
      code: 'setConf',
      data: {
        g_cost: 'Diagonal', //Manhattan, Diagonal, Euclidean
        g_weight: 1.0,
        h_cost: 'Euclidean', //Manhattan, Diagonal, Euclidean
        h_weight: 2.0,
        reduce_corner: false,
      },
    })

    for (let k in this.start) {
      if (this.start[k] && this.goal[k]) {
        start_arr.push(this.start[k].pos)
        goal_arr.push(this.goal[k].pos)
      }
    }

    this.astarWorker.postMessage({
      code: 'searchArr',
      data: { start: this.start, goal: this.goal },
    })

    return
  }

  // 自动布线
  autoConnectRun(conf) {
    this.astar = new AStar()


    this.astar.updateGraph(this.cell.bounding_box(), conf.grid_step, conf.neighbor_min_distance)

    // 障碍
    let avoidLayerPoints = this.getAvoidLayer(conf.avoid)
    for (let k in avoidLayerPoints) this.astar.graph.add_obstacle_grid(avoidLayerPoints[k])

    this.astar.setConf({
      g_cost: 'Diagonal', //Manhattan, Diagonal, Euclidean
      // g_cost: 'Manhattan', //Manhattan, Diagonal, Euclidean
      g_weight: 1.0,
      h_cost: 'Euclidean', //Manhattan, Diagonal, Euclidean
      // h_cost: 'Manhattan', //Manhattan, Diagonal, Euclidean
      h_weight: 1.0,
      reduce_corner: false,
    })
    let is_reverse = false

    let length = Object.keys(this.start).length
    let process_n = Math.ceil(length / 10)
    let cnt = 0
    for (let k in this.start) {
      this.start[k].path = []
      this.start[k].connect = false

      if (cnt % process_n == 0) {

      } else if (cnt == length - 1) {

      }

      // let kn = parseInt(k)
      // if (kn > 87 && kn < 90) {


      if (this.start[k] && this.goal[k]) {

        // let path = this.astar.search_one(this.start[k].pos, this.goal[k].pos, conf.grid_step * 3)
        let path = []
        if (is_reverse) path = this.astar.search_one(this.goal[k].pos, this.start[k].pos, conf.grid_step)
        else path = this.astar.search_one(this.start[k].pos, this.goal[k].pos, conf.grid_step)

        if (path.length > 0) {
          this.start[k].connect = true
          this.goal[k].connect = true
        }
        this.start[k].path = path
      }
      // }
      cnt += 1
      // if (cnt > 10) break
    }

    // 处理连接结果
    this.gds_paths = []
    for (let k in this.start) {
      if (!this.start[k].connect) continue
      let path = this.start[k].path

      if (path.length > 0) {
        if (is_reverse) {
          path = geolibs.pathMoveEndPoint(path, this.goal[k].pos)
          path = geolibs.pathMoveEndPoint(path.reverse(), this.start[k].pos)
          path = [].concat([this.start[k].end_pos], path, [this.goal[k].end_pos])
        } else {
          path = geolibs.pathMoveEndPoint(path, this.start[k].pos)
          path = geolibs.pathMoveEndPoint(path.reverse(), this.goal[k].pos)

          path = [].concat([this.start[k].end_pos], path.reverse(), [this.goal[k].end_pos])
        }
      }

      if (path && path.length > 0) {
        let flexpath = new QGdstk.FlexPath(path, 0, 0, 'natural', 'flush', 0, null, 1e-2, true, true, conf.layer, 0)
        this.gds_paths.push(flexpath)
      } else {
        this.start[k].connect = false
      }
    }



    let log = this.getLogs()
    window.bus.$emit('autoline_log_output', log)
  }

  getLogs() {
    let logs = []
    let error_total = 0
    let success_total = 0
    for (let k in this.start) {
      if (this.start[k] && this.goal[k]) {
        let type = 0
        if (this.start[k].connect) {
          success_total += 1
          type = 'success'
        } else {
          error_total += 1
          type = 'error'
        }
        let start = [this.start[k].pos[0].toFixed(3), this.start[k].pos[1].toFixed(3)]
        let goal = [this.goal[k].pos[0].toFixed(3), this.goal[k].pos[1].toFixed(3)]
        logs.push({
          type: type,
          text: `[${parseTime(new Date())}] 连线编号: ${k}, 起点：[${start}],\t\t终点：[${goal}]`,
        })
      }
    }

    return {
      info: logs,
      error_total: error_total,
      success_total: success_total,
    }
  }
}
