<template>
  <div class="app-container">
    <Header></Header>
    <div class="container">
      <div class="axis-container">
        <div class="ruler-x">
          <canvas id="ruler-x" :width="0" :height="0"></canvas>
        </div>
        <div class="ruler-y">
          <canvas id="ruler-y" :width="0" :height="0"></canvas>
        </div>
        <div class="axis">
          <canvas id="axis" class="axis" :width="0" :height="0" @contextmenu.prevent="onContextmenu()"></canvas>
        </div>
      </div>
      <!-- <el-card class="box-card"> -->
      <div class="box-card">
        <!-- <el-tabs tab-position="right" id="tabs">
          <el-tab-pane label="操作"> -->
        <div class="row">
          <el-button type="primary" style="width: 77px" @click="dialogVisible = true">{{ $t('messages.autoLineImportGds') }}</el-button>
          <el-button type="primary" style="width: 77px; background-color: #ffffff; color: #4d96df" @click="onExportGds">{{ $t('messages.autoLineExportGds') }}</el-button>
        </div>
        <div class="row-two" style="margin-top: 40px">
          <span style="width: 68px; height: 14px; margin-top: 10px; font-size: 12px; font-weight: 400; color: #333333">{{ $t('messages.autoLinePadName') }}:</span>
          <el-select style="width: 135px; height: 27px; border: 1px solid #dddddd; border-radius: 2px" v-model="PadName" filterable :placeholder="$t('messages.autoLineSelectPad')">
            <el-option v-for="item in cellOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="row-two" style="margin-top: 40px">
          <el-button style="width: 127px" type="primary" @click="onGetPad">{{ $t('messages.autoLineIdentificationPad') }}</el-button>
        </div>
        <div class="row-two" style="margin-top: 40px">
          <el-button style="width: 159px" type="primary" @click="onSelectPolygonStart">{{ $t('messages.autoLineSelectBitPoly') }}</el-button>
        </div>
        <div class="row-two" style="margin-top: 40px">
          <el-button style="width: 147px" type="primary" @click=";(resetBitZeroDialogVisible = true), (BitOffset = 0)">{{ $t('messages.autoLineResetBitOrigin') }}</el-button>
        </div>
        <div class="row-two" style="margin-top: 40px">
          <el-button style="margin-top: 0px" type="primary" @click="dialogRuleFormVisible = true">{{ $t('messages.autoLineConnectSet') }}</el-button>
        </div>
        <el-button style="margin-top: 40px" type="primary" @click="onAutoConnect">{{ $t('messages.autoLineAllWiring') }}</el-button>
        <el-button style="width: 79px; height: 28px; border: 1px solid #d44051; border-radius: 4px; color: #d44051" @click="onAutoConnectClear">{{ $t('messages.autoLineClearWiring') }}</el-button>
        <!-- <div class="row-two">
          <el-button style="margin-top: 10px" type="success" @click="LayerDialogVisible = true">查看图层</el-button>
        </div> -->
        <div class="row-two">
          <el-button style="margin-top: 40px" type="success" @click="LogDialogVisible = true">{{ $t('messages.autoLineViewLogs') }}</el-button>
        </div>
      </div>
      <el-dialog class="import-dialog" :title="$t('messages.autoLineImportGds')" :visible.sync="dialogVisible" width="550px" height="182px">
        <div class="dialog-body">
          <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-change="onPlaceImportGds" :auto-upload="false" :show-file-list="true" :file-list="fileList">
            <el-button style="width: 58px; height: 30px; background: #f4f4f4; border: 1px solid #dddddd; border-radius: 2px">{{ $t('messages.autoLineSelect') }}</el-button>
          </el-upload>
          <!-- <span>cell名称:</span> -->
          <el-select v-model="cellName" filterable :placeholder="$t('messages.autoLineSelectCell')">
            <el-option v-for="item in cellOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisibleOk">{{ $t('messages.autoLineOk') }}</el-button>
          <el-button style="width: 79px; height: 30px; background: #b0b0b0; border-radius: 4px; color: #ffffff" @click="dialogVisible = false">{{ $t('messages.autoLineCancel') }}</el-button>
        </span>
      </el-dialog>
      <el-dialog class="bit-dialog" :title="$t('messages.autoLineResetBitOrigin')" :visible.sync="resetBitZeroDialogVisible" width="570px" height="182px">
        <div class="dialog-body">
          <span>{{ $t('messages.autoLineBitSetOriginNumber') }}：</span>
          <el-input-number v-model="BitOffset" style="margin-right: 10px" :controls="false"></el-input-number>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="resetBitZeroDialogOk">{{ $t('messages.autoLineOk') }}</el-button>
          <el-button style="width: 79px; height: 30px; background: #b0b0b0; border-radius: 4px; color: #fff" @click="resetBitZeroDialogVisible = false">{{ $t('messages.autoLineCancel') }}</el-button>
        </span>
      </el-dialog>
      <el-dialog class="rule-dialog" :title="$t('messages.autoLineWiringRules')" :visible.sync="dialogRuleFormVisible">
        <!-- <div class="dialog-body"> -->
        <div class="rule">
          <div class="form-item" style="margin-top: 23px">
            <span>{{ $t('messages.autoLineConnectLineLayer') }}:</span>
            <!-- <el-input-number v-model="ruleForm.layer" size="small" :controls="false"></el-input-number> -->
            <!-- <div :style="{ 'background-color': scope.row.color, width: '30px', height: '16px' }"></div> -->
            <el-select v-model="ruleForm.layer" filterable :placeholder="$t('messages.autoLineConnectLineLayer')">
              <el-option v-for="item in cellLayerData" :key="item.layer" :label="item.layer" :value="item.layer"></el-option>
            </el-select>
          </div>
          <div class="form-item" style="margin-top: 15px">
            <span>a({{ $t('messages.autoLineMinDistance') }}):</span>
            <el-input-number v-model="ruleForm.neighbor_min_distance" size="small" :controls="false"></el-input-number>
          </div>
          <div class="form-item" style="margin-top: 15px">
            <span>b({{ $t('messages.autoLineGridSpace') }}):</span>
            <el-input-number v-model="ruleForm.grid_step" :controls="false"></el-input-number>
          </div>
          <div class="form-table" style="margin-top: 15px">
            <el-table class="table" :data="avoidLayerData" ref="layerTable" height="200">
              <el-table-column property="layer" :label="$t('messages.autoLineLayer')" align="center"></el-table-column>
              <el-table-column :label="$t('messages.autoLineColor')" prop="distance">
                <template slot-scope="scope">
                  <!-- <el-input-number :controls="false" v-model="scope.row.distance"></el-input-number> -->
                  <div :style="{ 'background-color': scope.row.color, width: '30px', height: '16px' }"></div>
                </template>
              </el-table-column>
              <el-table-column :label="$t('messages.autoLineLayer') + 'd(um)'" prop="distance">
                <template slot-scope="scope">
                  <el-input-number :controls="false" v-model="scope.row.distance"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column :label="$t('messages.autoLineAvoid')" prop="distance" align="center">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.selected"></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
            <div class="table_select">
              <div class="table_select_left">{{ $t('messages.autoLineAll') }}</div>
              <div class="table_select_right">
                <button class="table_select__btn" @click="toggleSelection('layerTable', true)">{{ $t('messages.autoLineSelectAll') }}</button>
                <button class="table_select__btn" @click="toggleSelection('layerTable', false)">{{ $t('messages.autoLineReverseSelect') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="image">
          <img alt="" style="width: 90%" src="../../../assets/icons/tools/连线设置.svg" />
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogRuleFormOk">{{ $t('messages.autoLineOk') }}</el-button>
          <el-button style="width: 79px; height: 30px; background: #b0b0b0; border-radius: 4px; color: #fff" @click="dialogRuleFormVisible = false">{{ $t('messages.autoLineCancel') }}</el-button>
        </div>
      </el-dialog>
      <el-dialog class="dialog" :title="$t('messages.autoLineLayer')" :visible.sync="LayerDialogVisible">
        <!-- <el-scrollbar style="height: 100%; width: 100%" wrap-style="overflow-x:hidden;"> -->
        <el-table :data="cellLayerData" style="width: 100%" height="612">
          <el-table-column label="layer">
            <template slot-scope="scope">
              <span>{{ scope.row.layer }}/{{ scope.row.datatype }}</span>
            </template>
          </el-table-column>
          <el-table-column label="color" align="center">
            <template slot-scope="scope">
              <el-tag :color="scope.row.color" style="width: 50px"></el-tag>
            </template>
          </el-table-column>
        </el-table>
        <!-- </el-scrollbar> -->
      </el-dialog>
      <el-dialog class="log-dialog" :title="$t('messages.autoLineLogs')" :visible.sync="LogDialogVisible" width="850px" height="486px">
        <el-container style="height: 441px">
          <!-- <el-header>输出日志</el-header> -->
          <el-main>
            <el-row v-for="(item, i) in log.info">
              <span v-if="item.type == 'success'" style="color: green" class="infinite-list-item">{{ item.text }}</span>
              <span v-if="item.type == 'error'" style="color: red" class="infinite-list-item">{{ item.text }}</span>
            </el-row>
            <!-- <br /> -->
          </el-main>
          <el-footer>
            <span style="color: red">errors total: {{ log.error_total }}</span>
            &nbsp;&nbsp;
            <span style="color: green">success total: {{ log.success_total }}</span>
          </el-footer>
        </el-container>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import i18n from '../../../common/lang/i18n'
import bus from '../../common/bus'
import logo from '../../../assets/logo-qeda2.jpg'
import Header from '@/components/homes/header/Header.vue'
import { Stage } from './stage/stage.js'
import { LayerColorHex } from './stage/shaders.js'
export default {
  components: { Header },
  filters: {},
  model: {
    prop: 'value',
  },
  data() {
    return {
      rulerWidth: 50,
      fileList: [],
      width: 800,
      height: 800,
      app: null,
      container: null,
      stage: null,
      dialogVisible: false,
      cellOptions: [],
      cellName: '',
      PadName: '',
      BitOffset: 0,
      resetBitZeroDialogVisible: false,
      cellLayerData: [],
      LayerDialogVisible: false,
      LogDialogVisible: false,
      avoidLayerData: [],
      formLabelWidth: '100px',
      ruleForm: {
        layer: 1,
        datatype: 0,
        neighbor_min_distance: 10,
        corner_min_distance: 10,
        grid_step: 10,
      },
      dialogRuleFormVisible: false,
      multipleSelection: [],
      log: {
        info: [],
        num: 0,
        error_total: 0,
        success_total: 0,
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.resize()
      this.stage = new Stage('axis')
    }, 500)
    window.bus.$on('AutoLine', data => {

    })
    window.bus.$on('autoline_log_output', data => {

      this.log = data
      this.LogDialogVisible = true
    })
  },
  created() {},
  methods: {
    resize() {
      let bodyHeight = document.body.clientHeight
      let bodyWidth = document.body.clientWidth
      let width = document.body.clientWidth - 407
      let height = document.body.clientHeight
      let axis_canvas = document.getElementById('axis')
      let ruler_x_canvas = document.getElementById('ruler-x')
      let ruler_y_canvas = document.getElementById('ruler-y')
      // 坐标系统
      axis_canvas.width = width
      axis_canvas.height = height
      // 设置尺子
      ruler_x_canvas.width = width
      ruler_x_canvas.height = 30
      ruler_y_canvas.width = 30
      ruler_y_canvas.height = height
      let operate = document.getElementsByClassName('operater-container')
      operate.width = 500

    },
    toggleSelection(table_name, all) {
      if (all) {
        this.$refs[table_name].data.forEach(item => {
          item.selected = true
        })
      } else {
        this.$refs[table_name].data.forEach(item => {
          item.selected = !item.selected
        })
      }
    },
    onUploadChange(file, list) {},
    onPlaceImportGds(file, fileList) {
      if (fileList.length > 1 && file.status !== 'fail') {
        fileList.splice(0, 1)
      } else if (file.status === 'fail') {
        fileList.splice(0, 1)
      }
      let self = this
      let event = event || window.event //NOSONAR
      let reader = new FileReader()
      reader.readAsArrayBuffer(file.raw)
      reader.onload = function (e) {
        // 转Uint8Array
        const blob = new Uint8Array(reader.result)
        window.QGdstk.FS.writeFile(file.name, blob)
        self.loadGds(file.name)
      }
    },
    loadGds(file_name) {
      this.library = window.QGdstk.read_gds(file_name)

      this.cellOptions = []
      if (this.library) {
        this.library.cells.forEach(element => {
          this.cellOptions.push({ value: element.name, label: element.name })
        })
      }
      this.stage.library.setLibrary(this.library)
    },
    dialogVisibleOk() {
      this.stage.setCell(this.cellName)
      this.dialogVisible = false
      let layers = this.stage.library.cell_layers_and_datatypes

      this.avoidLayerData = []
      this.cellLayerData = []
      if (layers) {
        layers.forEach(element => {
          this.avoidLayerData.push({
            layer: element[0],
            datatype: element[1],
            distance: 10,
            selected: false,
            color: LayerColorHex[element[0] % 10],
          })
          this.cellLayerData.push({
            layer: element[0],
            datatype: element[1],
            color: LayerColorHex[element[0] % 10],
          })
        })
      }
      this.avoidLayerData.sort((a, b) => {
        return a.layer - b.layer
      })
      this.cellLayerData.sort((a, b) => {
        return a.layer - b.layer
      })


    },
    dialogRuleFormOk() {
      this.dialogRuleFormVisible = false
    },
    resetBitZeroDialogOk() {
      this.resetBitZeroDialogVisible = false
      window.bus.$emit('autoline_bit_offset', this.BitOffset)
    },
    onGetPad() {
      window.bus.$emit('autoline_get_pad', this.PadName)
    },
    onBitOffsetChanged() {

      window.bus.$emit('autoline_bit_offset', this.BitOffset)
    },
    onSelectLine() {
      window.bus.$emit('autoline_select_mode', 1)
    },
    onSelectPolygonStart() {
      window.bus.$emit('autoline_select_polygon_start', 1)
    },
    onSelectPolygonPad() {
      window.bus.$emit('autoline_select_polygon_goal', 1)
    },
    onGenTable() {
      window.bus.$emit('autoline_table_gen', 1)
    },
    onAutoConnect() {
      let avoid = []
      this.avoidLayerData.forEach(element => {
        if (element.selected) {
          avoid.push(element)
        }
      })
      let conf = {
        layer: this.ruleForm.layer,
        datatype: this.ruleForm.datatype,
        neighbor_min_distance: this.ruleForm.neighbor_min_distance,
        corner_min_distance: this.ruleForm.corner_min_distance,
        grid_step: this.ruleForm.grid_step,
        avoid: avoid,
        bit_offset: this.BitOffset,
      }

      this.$message({
        message: '警告，自动布线中，请等待~',
        type: 'warning',
      })
      setTimeout(() => {
        window.bus.$emit('autoline_auto_connect', conf)
      }, 500)
    },
    onAutoConnectClear() {
      window.bus.$emit('autoline_auto_connect_clear', 1)
    },
    onExportGds() {
      let filename = 'export.gds'
      this.library.write_gds(filename)
      let mime = 'application/octet-stream'
      let content = QGdstk.FS.readFile(filename)
      let download_link = document.getElementById('download_gds')
      if (download_link == null) {
        download_link = document.createElement('a')
        download_link.id = 'download_gds'
        download_link.style.display = 'none'
        document.body.appendChild(download_link)
      }
      download_link.download = filename
      download_link.href = URL.createObjectURL(
        new Blob([content], {
          type: mime,
        })
      )
      download_link.click()
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #f4f4f4;
}
.el-button {
  width: 77px;
  height: 28px;
  border-radius: 4px;
  padding: 0px 0px;
  font-size: 12px;
  text-align: center;
}
.box-card {
  width: 327px;
  min-height: 1024px;
  background: #ffffff;
  border: 1px solid #dddddd;
  margin-left: 10px;
  margin-right: 0px;
  margin-top: 30px;
  padding: 21px 21px 21px 11px;
  .row {
    display: flex;
    flex-direction: row;
  }
  .row-two {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  /deep/ .el-select__caret {
    color: #a3a3a3 !important;
  }
  /deep/ .el-input__suffix {
    top: 3px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }
  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
  /deep/ .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }
}
.axis-container {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-rows: 30px 1fr;
  padding: 0px 0px 0px 0px;
  .ruler-x {
    grid-row-start: 1;
    grid-column-start: 2;
  }
  .ruler-y {
    grid-row-start: 2;
    grid-column-start: 1;
  }
  .axis {
    grid-row-start: 2;
    grid-column-start: 2;
    background-color: black;
  }
}
.table_select {
  padding: 0px;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #dddddd;
  .table_select_left {
    display: inline-block;
    width: 40%;
    margin: 6px 10px;
  }
  .table_select_right {
    display: inline-block;
    width: 55%;
    text-align: right;
    .table_select__btnGroup {
      cursor: pointer;
      font-size: 12px;
      margin-right: 20px;
      color: #0c7de6;
    }
  }
  .table_select__btn {
    padding: 1px 6px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 2px;
    margin-right: 4px;
    font-size: 12px;
  }
}
.import-dialog {
  /deep/ .upload-demo {
    height: 30px;
    display: flex;
    flex-direction: row;
  }
  /deep/.el-dialog {
    background: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 19px 10px 42px 14px;
  }
  .dialog-body {
    height: 30px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  /deep/ .el-select__caret {
    color: #a3a3a3 !important;
  }
  /deep/ .el-input__suffix {
    top: 3px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }
  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
  /deep/ .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }
}
.bit-dialog {
  /deep/.el-dialog {
    background: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 19px 10px 42px 14px;
  }
  .dialog-body {
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  /deep/ .el-input-number {
    width: 168px;
    height: 23px;
  }
  /deep/ .el-input {
    width: 168px;
    height: 23px;
    line-height: 21px;
    font-size: 11px;
  }
  /deep/ .el-input__inner {
    width: 168px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
  }
}
.rule-dialog {
  /deep/.el-dialog {
    width: 792px;
    height: 663px;
    background: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/ .el-dialog__header {
    width: 793px;
    height: 41px;
    background: #fff4f4;
  }
  /deep/ .el-dialog__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 0px 0px 0px;
  }
  /deep/ .el-input-number {
    width: 143px;
    height: 26px;
  }
  /deep/ .el-input {
    width: 143px;
    height: 26px;
    line-height: 24px;
    font-size: 11px;
  }
  /deep/ .el-input__inner {
    width: 143px;
    height: 26px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
  }
  /deep/ .el-select__caret {
    color: #a3a3a3 !important;
  }
  /deep/ .el-input__suffix {
    top: 2px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }
  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
  /deep/ .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    text-align: left;
    border-radius: 5px 0px 0px 5px;
  }
  .rule {
    width: 466px;
    height: 533px;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-left: 20px;
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    .form-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 11px 0px 11px;
    }
    .form-table {
      display: flex;
      flex-direction: column;
      padding: 0px 11px 0px 11px;
      height: 65%;
      .table {
        width: 100%;
        height: 100%;
        margin-top: 20px;
        background: #ffffff;
        border: 1px solid #dddddd;
        /deep/ .table_header {
          background: #e9eff3 !important;
        }
        /deep/ .el-table__cell {
          padding: 3px !important;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          background: #f4f4f4;
        }
        .disabled {
          color: #b2b2b2 !important;
        }
        /deep/ .el-input-number {
          width: 69px;
          height: 23px;
        }
        /deep/ .el-input {
          width: 69px;
          height: 23px;
          line-height: 21px;
          font-size: 11px;
        }
        /deep/ .el-input__inner {
          width: 69px;
          height: 23px;
        }
      }
    }
  }
  .image {
    width: 275px;
    height: 533px;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-right: 21px;
    margin-top: 19px;
    display: flex;
    justify-content: center;
  }
}
.log-dialog {
  /deep/.el-dialog {
    width: 736px;
    height: 486px;
    background: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border: 1px solid #dddddd;
    border-radius: 4px;
  }
  /deep/ .el-dialog__header {
    width: 793px;
    height: 41px;
    background: #fff4f4;
  }
  /deep/ .el-dialog__body {
    padding: 0px 0px 0px 0px;
  }
  .el-header,
  .el-footer {
    background-color: #b3c0d1;
    color: #333;
    text-align: center;
    height: 30px !important;
    line-height: 30px;
  }
  .el-aside {
    background-color: #d3dce6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
  .el-main {
    background-color: #e9eef3;
    color: #333;
    line-height: 15px;
  }
  body > .el-container {
    margin-bottom: 40px;
    flex-direction: column;
  }
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
}
</style>
